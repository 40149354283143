import {ActionManager, ExecuteCodeAction, Observable, Scene, MeshBuilder, Color3, Texture} from "@babylonjs/core";
import * as GUI from "@babylonjs/gui";




const sportList = [
    {
        'name': 'Replace.006'
    },
    {
        'name': 'Replace.007'
    },
    {
        'name': 'Replace.008'
    }
]

export class ShicaiHotSportManage {

    constructor(scene: Scene,player) {
        this.scene = scene
        this.onSportClickObservable = new Observable()
        this.advancedTexture = GUI.AdvancedDynamicTexture.CreateFullscreenUI('ui', true, this.scene)
        this.hotSportList = []
        this.hotSportMesh = []
        this.cameraMesh = MeshBuilder.CreateBox('cameraBox',{size:0.1},this.scene);//创建检测区域碰撞使用的Box
        this.cameraMesh.isPickable = false;
        this.cameraMesh.visibility = 0;
        this.cameraMesh.parent = player;

        // const btn = GUI.Button.CreateImageOnlyButton('gifbtn','test.gif')
        
        // btn.width = "200px"
        // btn.height = "200px"
        // this.advancedTexture.addControl(btn)
    }

    replaceMaterial(matUrl){
        if(this.currentReplaceMesh){
            let replaceMat = this.currentReplaceMesh.material;
            replaceMat.emissiveColor = Color3.White()
            replaceMat.albedoTexture = new Texture(matUrl,this.scene);
        }
    }

    async update() {

        this.sportList = sportList
        this.sportList.forEach(item => {
            const mesh = this.scene.getNodeByName(item.name)
            const hotSport =  GUI.Button.CreateImageButton(item.name,"",'/icon.png')
            hotSport.isVisible = false
            hotSport.color = 'transparent'
            hotSport.width ='200px'
            hotSport.height ='200px'
            hotSport.image.width = '200px'
            hotSport.image.height = '200px'
            hotSport.image.horizontalAlignment  = 2
            console.log(mesh)
            if (mesh) {
                this.advancedTexture.addControl(hotSport)
                hotSport.linkWithMesh(mesh)
            }
            hotSport.onPointerClickObservable.add(e=>{
                // console.log('click');
                this.currentReplaceMesh = mesh;
                window.game.cameraControl.isRoaming = false;
                window.game.cameraControl.stopRoaming();
                wanderStart(2)
                window.setMaterial('进入')
            })
            mesh.material.emissiveColor = Color3.White()
            mesh.material.emissiveIntensity = 0.5
            this.hotSportList.push(hotSport)
            this.hotSportMesh.push(mesh)


        })
        this.scene.meshes.filter(mesh => mesh.name.indexOf('ModelInteraction') !== -1).forEach(mesh=>{
            mesh.visibility = 0;
            mesh.isPickable = false;
            if(!mesh.actionManager){
                mesh.actionManager = new ActionManager(this.scene);
            }
            mesh.actionManager.registerAction(new ExecuteCodeAction(
                {
                    trigger: ActionManager.OnIntersectionEnterTrigger,
                    parameter: this.cameraMesh
                },
                (evt)=>{
                    console.log('enter')
                    this.hotSportList.forEach(hotSport=>{
                        hotSport.isVisible = true
                    })
                    
                }
            ))
            mesh.actionManager.registerAction(new ExecuteCodeAction(
                {
                    trigger: ActionManager.OnIntersectionExitTrigger,
                    parameter: this.cameraMesh
                },
                (evt)=>{
                    console.log('exit')
                    this.hotSportList.forEach(hotSport=>{
                        hotSport.isVisible = false;
                    })
                    operateBottomNav('关闭')
                }
            ))
        })

    }
}

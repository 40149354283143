import { 
    Scene,
    Engine,
    Color3,
    MeshBuilder,
    StandardMaterial,
    Texture,
    InstancedMesh,
    PBRMaterial,
    CubeTexture
} from "@babylonjs/core";

export class SceneParams {
    constructor(scene:Scene,tem_id:Number) {
        this.scene = scene
        this.tem_id = tem_id
    }
    //初始化场景函数
    initParams(){
        //通用处理
        //用MeshBuilder创建一个ground 地板  
        const ground = MeshBuilder.CreateGround("ground", { width: 1000, height: 1000 },this.scene)
        ground.checkCollisions = true
        ground.position.y = -0.15
        ground.visibility = 0
        this.scene.fogMode = 3
        this.scene.fogDensity = 0.001
        this.scene.fogStart = 0
        this.scene.fogEnd = 600
        this.scene.fogColor = Color3.FromHexString("#414424")
        this.scene.environmentIntensity = 2
        this.scene.imageProcessingConfiguration.vignetteEnabled = true
        this.scene.imageProcessingConfiguration.vignetteWeight = 1.5
        this.scene.imageProcessingConfiguration.vignetteStretch = 1
        this.scene.imageProcessingConfiguration.vignetteCameraFov = 0.6
        let skyBox = MeshBuilder.CreateSphere('sky', {}, this.scene)
        skyBox.checkCollisions = false
        skyBox.scaling.x = -1
        skyBox.scaling.scaleInPlace(300)
        skyBox.applyFog = false
        
        //特殊处理 如果有一样的参数会直接覆盖
        let tem_id = Number(this.tem_id)
        let skyMat = new StandardMaterial('skyMat', this.scene)
        let skyTexture = new Texture('sky.jpg', this.scene, false, false)

        this.scene.autoClear = false; // Color buffer
        this.scene.autoClearDepthAndStencil = false; // Depth and stencil, obviously


        // console.log(skyBox);
        if(tem_id != 76){

            this.scene.createDefaultLight()
        }
        // this.scene.createDefaultEnvironment({ createSkybox: false })
        switch (tem_id) {
            case 37:
                ground.position.y = 0
                break;
            case 38:
                break;
            case 39:
                break;
            case 40:
                break;
            case 41:
                break;
            case 42:
                break;
            case 43:
                break;
            case 44:
                this.scene.environmentIntensity = 1;
                break;
            case 47:
                this.scene.fogMode = 2
                this.scene.fogColor = Color3.FromHexString('#E585ff')
                this.scene.fogDensity = 0.007
                break;
            case 51:
                ground.position.y = -0.1
                break;
            case 54:
                ground.position.y = 0.1
                break;
            case 58:
                ground.position.y = 0.1
                break;
            case 61:
                ground.position.y = -0.1
                break;
            case 63:
                ground.position.y = 0
                break;
            case 64:
                ground.position.y = 0.1
                break;
            case 66:
                ground.position.y = 0.1
                skyTexture = new Texture('sky3.png', this.scene, false, false)
                break;
                case 68:
                ground.position.y = 0.0
                break;
            case 69:
                skyTexture = new Texture('sky1.png', this.scene, false, false)
                break;
            case 70:
                skyTexture = new Texture('sky1.png', this.scene, false, false)
                ground.position.y = 0.25
                break;
            case 72:
                ground.position.y = 0.05
                break;
            case 76:
                skyTexture = new Texture('sky3.png', this.scene, false, false)
                // this.scene.fogMode = 3
                // this.scene.fogColor = Color3.FromHexString('#E585ff')
                // this.scene.fogDensity = 0.01
                // this.scene.fogStart = 1.0;
                // this.scene.fogEnd = 350.0;
                break;
            case 74:
            case 77:
            case 79:
                skyTexture = new Texture('sky3.png', this.scene, false, false)
                break;
            case 80:
                skyTexture = new Texture('sky3.png', this.scene, false, false)
                ground.setEnabled(false);
                break;
            case 82:
                MeshBuilder.CreateGround("ground1", { width: 1000, height: 1000 },this.scene)
                break
            case 86:
                skyTexture = new Texture('sky4.png', this.scene, false, false)
                break
            case 87:
                skyTexture = new Texture('sky5.png', this.scene, false, false)
                skyBox.scaling.scaleInPlace(2)
                break
            case 96:
                skyTexture = new Texture('sky3.png', this.scene, false, false)
                skyBox.scaling.scaleInPlace(2)
                break
            case 97:
                skyTexture = new Texture('sky3.png', this.scene, false, false)
                skyBox.scaling.scaleInPlace(2)
                break
            case 99:
                ground.position.y = 0.05
                break;
            case 100:
                skyTexture = new Texture('sky5.png', this.scene, false, false)
                skyBox.scaling.scaleInPlace(2)
                break;
            case 101:
                skyTexture = new Texture('sky5.png', this.scene, false, false)
                skyBox.scaling.scaleInPlace(2)
                break;
            case 102:
                skyTexture = new Texture('sky5.png', this.scene, false, false)
                skyBox.scaling.scaleInPlace(2)
                break;
            case 103:
                break;
            case 104:
                skyTexture = new Texture('sky5.png', this.scene, false, false)
                skyBox.scaling.scaleInPlace(2)
                break;
            case 105:
                skyTexture = new Texture('sky5.png', this.scene, false, false)
                skyBox.scaling.scaleInPlace(2)
                break;
            case 106:
                skyTexture = new Texture('sky5.png', this.scene, false, false)
                skyBox.scaling.scaleInPlace(2)
                break;
            case 107:
                skyTexture = new Texture('sky5.png', this.scene, false, false)
                skyBox.scaling.scaleInPlace(2)
                break;
            case 108:
                skyTexture = new Texture('sky5.png', this.scene, false, false)
                skyBox.scaling.scaleInPlace(2)
                break;
        }
        skyMat.backFaceCulling = false;
        skyMat.diffuseTexture = skyTexture
        skyMat.emissiveColor = Color3.White()
        skyBox.material = skyMat
        skyBox.isPickable = false
    }
}

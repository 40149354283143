import {ICameraInput} from "@babylonjs/core/Cameras/cameraInputsManager";
import {ArcRotateCamera, KeyboardEventTypes, Matrix,  Vector3, PointerEventTypes} from "@babylonjs/core";
import {PlayerTPS} from "@/js/playerControl/playerTPS";
import * as GUI from "@babylonjs/gui";

export class TpsInput implements ICameraInput<ArcRotateCamera> {

    w = false
    s = false
    a = false
    d = false

    x = 0
    y = 0
    ox = 0
    oy = 0

    constructor(tpsPlayer: PlayerTPS) {
        this.player = tpsPlayer
        this.bSendCameraData = false;
        this.lastAlpha = 0;
        this.lastBeta = 0;
        this.lastTranslate = Vector3.Zero()
        this.lastCameraRotateJsonString = ''
        this.player.scene.onPointerObservable.add((pointerInfo)=>{//监听屏幕点击事件
            switch(pointerInfo.type){
                // case PointerEventTypes.POINTERTAP:
                //     console.log("POINTERTAP");
                //     break;
                case PointerEventTypes.POINTERDOWN:
                    // console.log("POINTERDOWN");
                    this.bSendCameraData = true;
                    break;
                // case PointerEventTypes.POINTERPICK:
                //     console.log("POINTERPICK");
                //     break;
                case PointerEventTypes.POINTERUP:
                    // console.log("POINTERUP");
                    this.bSendCameraData = false;
                    break;
            }

        })
    }

    getClassName(): string {
        return 'tpsInput'
    }

    getSimpleName(): string {
        return "tpsInput"
    }

    attachControl(noPreventDefault: boolean) {
        console.log('input attach')
        // keyboard
        this.obs = this.camera._scene.onKeyboardObservable.add(e => {
            if (e.event.key.toLowerCase() === 'w') {
                this.w = e.type === KeyboardEventTypes.KEYDOWN

            }
            if (e.event.key.toLowerCase() === 's') {
                this.s = e.type === KeyboardEventTypes.KEYDOWN
            }
            if (e.event.key.toLowerCase() === 'a') {
                this.a = e.type === KeyboardEventTypes.KEYDOWN
            }
            if (e.event.key.toLowerCase() === 'd') {
                this.d = e.type === KeyboardEventTypes.KEYDOWN

            }
        })

        //touch
        this.joyPanel = GUI.AdvancedDynamicTexture.CreateFullscreenUI('joy')
        this.joyOut = new GUI.Ellipse('joyOut')
        this.joyOut.isPointerBlocker = true
        this.joyOut.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT;
        this.joyOut.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_BOTTOM;
        this.joyOut.height = '350px'//175
        this.joyOut.width = '350px'//175
        this.joyOut.left = '100px'
        this.joyOut.top = '-50px'
        this.joyOut.thickness = 0
        this.joyPanel.addControl(this.joyOut)

        this.imag1 = new GUI.Image('image1', 'out.png')
        this.joyOut.addControl(this.imag1)

        // this.joyInner = new GUI.Ellipse('joyInner')
        
        this.joyInner = new GUI.Image('image2', 'inline.png')
        this.joyInner.height = '120px'//71
        this.joyInner.width = '120px'//71
        this.joyOut.addControl(this.joyInner)
        this.switchJoy(0)


    }
    switchJoy(visibility)
    {
        // this.joyInner.alpha = visibility
        this.joyOut.alpha = visibility
    }

    registerJoy()
    {
        this.joyOut.onPointerDownObservable.add(e => {
            this.isdown = true
        })

        this.joyOut.onPointerUpObservable.add(e => {
            this.isdown = false

            this.ox = 0
            this.oy = 0
            this.joyInner.top = this.oy
            this.joyInner.left = this.ox
        })

        this.joyOut.onPointerMoveObservable.add(e => {
            if (this.isdown) {
                this.ox = e.x - this.joyOut.centerX
                this.oy = e.y - this.joyOut.centerY
                this.joyInner.top = this.oy
                this.joyInner.left = this.ox
            }
        })
    }

    detachControl() {
        console.log('input detach')
        if (this.obs) {
            this.obs.unregisterOnNextCall = true
        }
    }

    checkInputs() {
        let num = 1
        this.x = (this.a ? -num : 0) + (this.d ? num : 0) + this.ox
        this.y = (this.w ? num : 0) + (this.s ? -num : 0) - this.oy
        let currentTranslate = new Vector3(this.y / -1, 0, this.x).normalize()
        currentTranslate = Vector3.TransformNormal(currentTranslate, Matrix.RotationY(this.camera.alpha * -1))

        if(currentTranslate.length() > 0)
        {
            let translateJson = {translate:currentTranslate}
            let translateJsonString = JSON.stringify(translateJson);
            if(this.lastCameraRotateJsonString != translateJsonString){
                this.lastCameraRotateJsonString = translateJsonString;
                //发送人物移动json
                // console.log(translateJsonString)
            }
    
        }
        else{
            this.lastTranslate = Vector3.Zero()
            let translateJson = {translate:this.lastTranslate}
            let translateJsonString = JSON.stringify(translateJson);
            if(this.lastCameraRotateJsonString != translateJsonString){
                this.lastCameraRotateJsonString = translateJsonString;
                //发送人物移动json
                // console.log(translateJsonString)
            }
        }
        if(this.bSendCameraData)
        {
            if(this.lastAlpha !==this.player.camera.alpha && this.lastBeta !== this.player.camera.beta){

                let cameraRotateJson = {alpha:this.player.camera.alpha,beta:this.player.camera.beta}
                this.lastAlpha = this.player.camera.alpha
                this.lastBeta = this.player.camera.beta
                let cameraRotateJsonString = JSON.stringify(cameraRotateJson);
                //发送镜头旋转json
                // console.log(cameraRotateJsonString)
            }
        }
        // console.log()
        //调用角色移动函数
        this.player.move(currentTranslate)


        // if (translate.length() > 0) {
        //     let targetRotation = Quaternion.RotationQuaternionFromAxis(translate.clone(), Vector3.Up(), translate.cross(Vector3.Up())).multiply(Quaternion.RotationYawPitchRoll(Math.PI * 1.5, 0, 0))
        //     this.player.mesh.rotationQuaternion = Quaternion.Slerp(this.player.mesh.rotationQuaternion, targetRotation, 0.2)
        //     translate.scaleInPlace(0.06)
        //     translate.addInPlace(new Vector3(0, -0.1, 0))
        //     this.player.moveWithCollisions(translate)
        //     // console.log("translate",translate)
        //     this.player.animBlender.setTargetAnim('run')
            
        // } else {
        //     this.player.animBlender &&  this.player.animBlender.setTargetAnim('idle')

        // }

    }

}

import {
    Color3,
    Mesh,
    Node,
    PBRMaterial,
    Scene,
    StandardMaterial,
    Tags,
    Texture,
    Tools,
    VideoTexture,
    ActionManager,
    ExecuteCodeAction,
    SceneLoader,
    Vector3
} from "@babylonjs/core";

import axios from "axios"
import * as GUI from "@babylonjs/gui";

import {getQueryVariable} from "@/js/tool";

//读取配置config.json文件
async function getGalleryConfig() {
    let response = await axios.get("./template/" + getQueryVariable("tem_id") + "/config.json")
    return response.data
}

export const locateType = {
    TEXT: 1,
    IMG: 2,
    VIDEO: 3,
    AUDIO: 4,
    MODEL: 5,
}

export const planeUrl = {}

export class GalleryManage {
    constructor(scene: Scene) {
        this.scene = scene
        this.init()
        this.hotSports=[]

    }

    setHotSportsVisible(isVisible,mesh){
        this.hotSports.map(hotSport=>{
            hotSport.isVisible = isVisible ? 1 : 0;
        })
    }

    async init() {
        this.locates = []
        let config = await getGalleryConfig()
        config.send_locate.forEach(e => {
            
            if(e.file_name){
                let node = this.scene.getNodeByName(e.file_name)
                if (node) {
                    // console.log(node)
                    this.locates.push(new GalleryLocate(e.title, e.locate_type, node,e.file_name))
                }
            }else{
                let node = this.scene.getNodeByName(e.title)
                if (node) {
                    // console.log(node)
                    this.locates.push(new GalleryLocate(e.title, e.locate_type, node,e.file_name))
                } 
            }

        })
        this.update()
    }

    update() {
        this.locates.forEach((locate) => {
            locate.update(this)
        })
    }
}

export class GalleryLocate {
    constructor(title: string, locate_type: number, node: Node,file_name:string) {
        this.title = title
        this.locate_type = locate_type
        this.node = node
        this.file_name = file_name
    }
    getBoxMax(vec:Vector3){
        let max = vec.x>=vec.y ? vec.x :vec.y;
        max = max >= vec.z ? max : vec.z;
        return max;
    }
    getMultiBoxStandSize(Meshs){
        let meshArr = [];
        Meshs.forEach(newBox => {
            // console.log(newBox.getBoundingInfo())
            let maxNew = newBox.getBoundingInfo().boundingBox.maximumWorld
            //新模型的包围盒最小值
            let minNew = newBox.getBoundingInfo().boundingBox.minimumWorld
            // console.log(newBox.getBoundingInfo().boundingSphere.minimumWorld)
            //边界盒子 宽高深
            // let vecNew = new Vector3(maxNew._x - minNew._x , maxNew._y - minNew._y , maxNew._z - minNew._z)
            // let vecNew = newBox.getBoundingInfo().boundingSphere.radiusWorld
            
            //新模型最长边
            // let standSizeNew = this.getBoxMax(vecNew)
            let standSizeNew = newBox.getBoundingInfo().boundingSphere.radius
            console.log(standSizeNew)
            // console.log(newBox,standSizeNew,newBox.getBoundingInfo())
            meshArr.push(standSizeNew)
        });
        let max = meshArr.reduce((a,b) => {
            return b > a ? b : a;
        })
        return max;
    }
    //传入场景内原有模型与替换模型  根据包围盒最长边的比例换算出scaling
    getBoxScaling(iniBox,newBox){

        //原有模型的包围盒最大值
        let max = iniBox.getBoundingInfo().boundingBox.maximum
        //原有模型的包围盒最小值
        let min = iniBox.getBoundingInfo().boundingBox.minimum
        //边界盒子 宽高深
        let vec = new Vector3(max._x-min._x,max._y-min._y,max._z-min._z)
        //原有模型最长边
        // let standSize = this.getBoxMax(vec)
        let standSize = iniBox.getBoundingInfo().boundingSphere.radius

        console.log(standSize)
        // //新模型的包围盒最大值

        let standSizeNew = this.getMultiBoxStandSize(newBox)
        console.log(standSizeNew)
        let scale = standSize/standSizeNew;//得到放大或缩小的倍数
        // console.log(scale)
        return scale;
    }


    setButtonByNode(node){
        // console.log(hotSport.name)
        let advancedTexture = GUI.AdvancedDynamicTexture.CreateFullscreenUI('ui')
        let hotSport =  GUI.Button.CreateImageButton(node.name,'','/icon.png')
        hotSport.isVisible = 0
        hotSport.color = 'transparent'

        hotSport.width ='200px'
        hotSport.height ='200px'
        hotSport.image.width = '200px'
        hotSport.image.height = '200px'
        hotSport.image.horizontalAlignment  = 2
        advancedTexture.addControl(hotSport);
        hotSport.linkWithMesh(node);
        this.manage.hotSports.push(hotSport)

        return hotSport;

    }


    async update(manage) {
        this.manage = manage;
        let res = await axios.get("https://ky3d.com/addons/yb_guanwang/core/index.php/api/index/getTemplateData", {
            params: {
                mch_id: getQueryVariable('mch_id'),
                title: this.title,
                tem_id: getQueryVariable('tem_id')

            }
        })
        // console.log(res.data.data)
        let url='';
        let state = false;
        if(!res.data.data){//测试
            url="./template/49/model/A_Model.glb";
            state = true;
        }else{
            url = res.data.data.source
            state = res.data.data.status === 1 //1为有, 2为无
        }
        // state = true;
        if (url && res.data.data) {
            url = url.replace('https://ky3d.com', 'wapapi')
            url = url.replace('https://w7cc.ky3d.com','w73dapi')
            // url = url + '?v=' +  Math.floor(Math.random() * 1000 + 1)
        }
        // this.locate_type = res.data.data.locate_type
        this.locate_type = parseInt(this.locate_type)

        switch (this.locate_type) {
            case locateType.TEXT:
                break;
            case locateType.IMG:
                console.log(111)
                if (this.node instanceof Mesh && state) {
                    url = url + '?v=1' 
                    let mat = this.node.material.clone(this.node.name)
                    this.node.material = mat;

                    if (mat instanceof StandardMaterial) {
                        mat.diffuseTexture = new Texture(url, this.node._scene, false, false)
                    } else if (mat instanceof PBRMaterial) {
                        const tex = new Texture(url, this.node._scene, false, false)
                        mat.albedoTexture = tex
                        mat.emissiveTexture = tex
                        console.log('img')
                    }
                    mat.transparencyMode = 3
                    let name = this.node.name
                    planeUrl[name] = url
                    let pu = JSON.stringify(planeUrl)
                    localStorage.setItem('pu',pu)
                    this.node.actionManager = new ActionManager(this.node._scene)
                    this.node.actionManager.registerAction(
                        new ExecuteCodeAction({ trigger: ActionManager.OnPickTrigger },
                            (e) => {
                                // console.log(234)
                                zoomImg(url,name)
                            })
                    )
                }
                break;
            case locateType.AUDIO:
                break;
            case locateType.VIDEO:
                if (this.node instanceof Mesh && state && url !== "素材视频地址") {
                    let mat = this.node.material.clone(this.node.name)
                    this.node.material = mat;
                    console.log(mat instanceof StandardMaterial)
                    if (mat instanceof StandardMaterial) {
                        mat.emissiveTexture = new VideoTexture(this.title + "_video", url, this.node._scene, false, true)
                    } else if (mat instanceof PBRMaterial) {
                        let tex = new VideoTexture(this.title + "_video", url, this.node._scene, false,true,VideoTexture.TRILINEAR_SAMPLINGMODE,{
                            // poster:'play.png',
                            loop:true,
                            autoUpdateTexture:true,
                            autoPlay:false,
                            muted:true
                        });
                        mat.albedoTexture = tex
                        mat.emissiveTexture = tex
                        mat.emissiveIntensity = 1
                        mat.emissiveColor = Color3.White()
                        mat.roughness = 0.1


                        tex.video.muted = true
                        this.node.actionManager = new ActionManager(this.node._scene);
                        this.node.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickUpTrigger,()=>{

                            if(!tex.video.paused && !tex.video.muted){
                                tex.video.pause();
                            }else{
                                tex.video.play();
                                if(window.game.tem_id == 96 || window.game.tem_id == 97){
                                    window.openVideo(url)
                                }
                                else{
                                    tex.video.muted =false;
                                    tex.video.volume = 0.5;
                                }


                            }
                    }))
                        // this.node.actionManager = new ActionManager(this.node._scene)
                        // this.node.actionManager.registerAction(
                        //     new ExecuteCodeAction({ trigger: ActionManager.OnPickTrigger },
                        //         (e) => {
                        //             tex.video.play()
                        //         })
                        // )
                    }
                }
                break;
            case locateType.MODEL:
               //原有模型
               let node = this.node
            //    console.log(node)
               //原有模型的绝对定位
               let absolutePosition = this.node.getAbsolutePosition()
               let absoluteRotation = this.node.rotation;
            //    console.log(absoluteRotation)
               
               if(url){
                   this.node.isVisible = false

                   const { meshes,transformNodes } = await SceneLoader.ImportMeshAsync("", "", url, this.node._scene);
                //    console.log(meshes)
                //    const { meshes } = await SceneLoader.ImportMeshAsync("", "./template/48/", "test.glb", this.node._scene);
                   let scaling = this.getBoxScaling(node,meshes)
    
                   // meshes[0].setParent(this.node)
                   // meshes[0].position = Vector3.Zero()
                   let hotSport = this.setButtonByNode(node)

                   hotSport.onPointerClickObservable.add(e=>{
                    console.log(url)
                    showModel(url);
                   })
                   meshes.forEach((item,k) => {
                       if(k>0){
                           item.setAbsolutePosition(absolutePosition)
                            // item.rotation =this.node.rotation
                           item.scaling.x = scaling;
                           item.scaling.y = scaling;
                           item.scaling.z = scaling;
                        //    console.log(scaling)
                        //    item.actionManager = new ActionManager(this.node.scene);
                        //    item.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickTrigger,
                        //     (e)=>{
                        //         showModel(url);
                        //     }))
                       }
                   });

                }
                break;

        }
    }
}
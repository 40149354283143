import {ArcRotateCamera, Mesh, Scene, SceneLoader,Quaternion, Vector3, PointerEventTypes, setAndStartTimer, Animation, Viewport, SceneLoaderAnimationGroupLoadingMode} from "@babylonjs/core";
import {AnimBlender} from "@/js/animBlender";
import {TpsInput} from "@/js/playerControl/tpsInput";



export class PlayerTPS extends Mesh {
    constructor(name: string, characterFileName,gender, scene: Scene) {
        super(name, scene)
        this.scene = scene;
        this.camera = new ArcRotateCamera('player camera', 0, 1.5, 1, new Vector3(0,1.5,0))
        scene.activeCamera = this.camera;
        this.tpsInput = new TpsInput(this)
        this.initMesh(characterFileName,gender).then()
        this.camera.inputs.add(this.tpsInput)
        this.camera.attachControl()
        this.camera.parent = this
        this.camera.checkCollisions = false
        this.camera.panningDistanceLimit = 0.01
        this.camera.radius = 4.5
        this.camera.upperBetaLimit = 1.88
        this.camera.lowerRadiusLimit = 1.5
        this.camera.upperRadiusLimit = 4.5
        this.camera.wheelPrecision = 25
        this.camera.minZ = 0.01;
        this.meshes = []
        this.canPickMove = false;
        this.playingAnim = null;
        this.agentIndex = -1;
        this.isMove = false;
        this.AnimIdleName = "Idle";
        this.currentMoveState = "Walking"
        this.currentSpeed = 0.1;

        this.isPosing = false;
        this.isActive = true;


        this.currentAnimState = this.AnimIdleName

        this.switchVisibility(false);

        //注册角色动画状态机
        scene.onBeforeAnimationsObservable.add(e=>{
            // console.log(window.game.cameraControl.playerCamera.currentAnimState);
            if(this.animBlender){
                this.animBlender.setTargetAnim(this.currentAnimState)
            }
        })
        this.isRotate = false;
        this.isClickMove = true;
        let lastAlpha,lastBeta;
        scene.onBeforeRenderObservable.add(e=>{
            if(lastAlpha != this.camera.alpha || lastBeta != this.camera.beta)
            {
                this.isRotate = true;
                lastAlpha = this.camera.alpha;
                lastBeta = this.camera.beta;
            }else{
                this.isRotate = false;
            }
            if(!this.isRotate && !this.isMove){
                this.isClickMove = true;
            }else{
                this.isClickMove = false;
            }
            // console.log(this.isClickMove)

        })
        


        window.addEventListener('keydown', e => {
            if (e.key == 'q') {
                this.switchSpeed();
            }
            if(e.key == "1"){
                
            }
        })


    }


    dance(inNum){


        if(!this.isPosing){

            this.isPosing = true;

            switch(inNum){
                case 1:{
                    this.currentAnimState = 'SillyDancing'
                    break;
                }
                case 2:{
                    this.currentAnimState = 'GangnamStyle'
                    break;
                }
                case 3:{
                    this.currentAnimState = 'HipHop'
                    break;
                }
                case 4:{
                    this.currentAnimState = 'SalsaDancing'
                    break;
                }
                case 5:{
                    this.currentAnimState = 'Waving'
                    break;
                }
            }
            setTimeout(()=>{
                this.isPosing = false;
                this.currentAnimState = this.AnimIdleName
                // scene.getAnimationGroupByName('Greeting').pause();
            },2500)
        }
    }

    switchSpeed(){
        this.removeAgent()
        if(this.currentMoveState == "Walking")
        {
            this.currentMoveState = "Running";
            this.currentSpeed = 0.2;
            if(window.game.navigationManager){
                window.game.navigationManager.agentParams.maxSpeed = 10.5;
            } 
        }else{
            this.currentMoveState = "Walking";
            this.currentSpeed = 0.1;
            if(window.game.navigationManager){
                window.game.navigationManager.agentParams.maxSpeed = 3.5;
            }
        }
    }

        TeleportTo(target) {
            
            let startPos = this.position.clone();
            let endPos = target.clone().add(Vector3.Up().scale(0.1));
            let len = Vector3.Distance(startPos, endPos)
            let lastRate = 0;

            let time = 2;

            if(len>5){
                time = len/5
            }
            let pAnim = this.cameraAnim(endPos,time);
            this.animations=[pAnim];
           this.playingAnim = this.scene.beginAnimation(this,0,time*30,false,undefined,()=>{
            this.playingAnim = null;
           });
            // this.playingAnim = this.scene.beginDirectAnimation(this,pAnim,0,time*30,false,undefined,()=>{
            //     this.playingAnim = null;
            // });

        }
        //相机动画(传入位置、运动时间(1s = 30fps))
        cameraAnim(position,timeFrame){
            const startFrame = 0;
            const frameRate = 30;
            const endFrame = timeFrame*frameRate;
            const pKeyFrames = [];
            pKeyFrames.push({
              frame: startFrame,
              value: this.position
            })
            pKeyFrames.push({
              frame: endFrame,
              value: position
            })
        
            const pAnim = new Animation('pAnim','position',frameRate,Animation.ANIMATIONTYPE_VECTOR3,Animation.ANIMATIONLOOPMODE_CYCLE);
            pAnim.setKeys(pKeyFrames);
    
            return pAnim;
        }
    switchVisibility(isVisible){
        this.tpsInput.joyOut.isVisible =isVisible ? 1 : 0
        // this.tpsInput.joyInner.isVisible =isVisible ? 1 : 0
        this.isActive = isVisible;

    }

    switchFirstPerson(bSwitch)
    {
        if(bSwitch)
        {
            this.camera.lowerRadiusLimit = 0
            this.camera.upperRadiusLimit = 0
            this.camera.upperBetaLimit = Math.PI
            this.camera.radius=0;
            this.canPickMove = true;
            this.meshes.map(mesh=>{
                mesh.visibility = 0;
            })
            this.playingAnim && this.playingAnim.stop()
        }
        else{
            this.camera.upperBetaLimit = 1.83
            this.camera.lowerRadiusLimit = 1.3
            this.camera.upperRadiusLimit = 4.5
            this.camera.radius = 4.5
            this.canPickMove = false;
            this.meshes.map(mesh=>{
                mesh.visibility = 1;
            })
            this.playingAnim && this.playingAnim.stop()
            this.moveWithCollisions(new Vector3(0,-50,0))
        }
    }

    removeAgent(){
        if(window.game.navigationManager){
            window.game.navigationManager.removeCrowd(this.agentIndex)
            window.game.navigationManager.removeDecal()
            this.agentIndex = -1
        }
    }

    move(translate){
        if(!this.isPosing)
        {

            if (translate.length() > 0) {
                this.isMove = true;
                this.removeAgent();
                let targetRotation = Quaternion.RotationQuaternionFromAxis(translate.clone(), Vector3.Up(), translate.cross(Vector3.Up())).multiply(Quaternion.RotationYawPitchRoll(Math.PI * 1.5, 0, 0))
                this.mesh.rotationQuaternion = Quaternion.Slerp(this.mesh.rotationQuaternion, targetRotation, 0.2)
                translate.scaleInPlace(this.currentSpeed)
                translate.addInPlace(new Vector3(0, -0.1, 0))
                this.moveWithCollisions(translate)
                // console.log("translate",translate)
                // this.animBlender.setTargetAnim(this.currentMoveState)
                this.currentAnimState = this.currentMoveState
                
            } else {
                this.isMove = false;
                // this.animBlender &&  this.animBlender.setTargetAnim('Idle')
                this.currentAnimState = this.AnimIdleName
                // this.scene.stopAllAnimations()
    
            }
        }
    }

    async initMesh(characterFileName,gender) {
        // SceneLoader.ImportMesh('', '', 'G_girl03.glb', this._scene,
        SceneLoader.ImportMesh("",characterFileName ,"", this._scene,
            (meshes, particle, askeleton, animtions, nodes) => {
                this._scene.activeCamera = this.camera
                // console.log(this._scene.activeCamera)
                this.mesh = meshes[0]
                this.meshes = meshes;
                this.mesh.parent = this
                this.scaling = new Vector3(1.5,1.5,1.5);
                // console.log(animtions)
                this.ellipsoid = new Vector3(0.5, 1, 0.5)
                this.ellipsoidOffset = new Vector3(0, 0.85, 0)
                this.tpsInput.switchJoy(1);
                this.tpsInput.registerJoy();
                // let anim_url = '/characterMesh/RPM_Anim_Man.glb'
                let anim_url = 'https://qn.ky3d.com/rpm_anim/RPM_Anim_Man.glb'
                if(gender == 1){
                    anim_url = 'https://qn.ky3d.com/rpm_anim/RPM_Anim_Woman.glb'
                }

                SceneLoader.ImportAnimations(anim_url,"",this._scene,false,
                // SceneLoader.ImportAnimations('/characterMesh/girl_idle.glb',"",this._scene,false,
                // SceneLoader.ImportAnimations('/characterMesh/RPM_Anime_Combined.glb',"",this._scene,false,
                SceneLoaderAnimationGroupLoadingMode.NoSync,null,function(scene){
                        const anims = [];
                        anims.push( scene.getAnimationGroupByName('Idle'))
                        anims.push( scene.getAnimationGroupByName('Walking'))
                        anims.push( scene.getAnimationGroupByName('Running'))
                        anims.push( scene.getAnimationGroupByName('SillyDancing'))
                        anims.push( scene.getAnimationGroupByName('GangnamStyle'))
                        anims.push( scene.getAnimationGroupByName('HipHop'))
                        anims.push( scene.getAnimationGroupByName('SalsaDancing'))
                        anims.push( scene.getAnimationGroupByName('Waving'))
                        // anims.push( scene.getAnimationGroupByName('Idle'))
                        console.log(anims)
                        window.game.cameraControl.playerCamera.animBlender = new AnimBlender(anims, scene, "Idle")
                        // window.game.sceneMeshParams.createNavigation('collision');

                        window.game.cameraControl.stayTimeListen();
                        if(window.game.tem_id !== 44 && window.game.tem_id !== 70)
                        {
                            window.game.cameraControl.interactionListen();
                        }

                    },undefined,(scene,message,exception)=>{
                        console.log(message)
                    }
                )
                this.switchVisibility(true);
                // this.animBlender = new AnimBlender(animtions, this._scene, 'idle')
                // this.emoji = new EmojiCreator(this,scene)
            })
    }

}

import { Mesh, Quaternion, SceneLoader, Vector3 } from "@babylonjs/core";
import {AnimBlender} from "@/js/animBlender";


export class PlayerAI extends Mesh {
    constructor(name: string, scene: Scene) {
        super(name,scene)
        this.agIndex = -1;
        this.initMesh()
    }

    moveTo(targetPos)
    {
        // console.log("AI",this.agIndex,'Move')
        this.crowd.agentGoto(this.agIndex, this.navigationPlugin.getClosestPoint(targetPos));
        setTimeout(()=>{
            let targetPos = new Vector3((Math.random()*10),0.41,(Math.random()*10));
            this.moveTo(targetPos)
        },3000+Math.random()*3000)
    }

    beginRandomWalk()
    {
        setTimeout(()=>{
            let targetPos = new Vector3((Math.random()*10),0.41,(Math.random()*10));
            this.moveTo(targetPos)
        },3000+Math.random()*3000)
    }


    async initMesh() {
        SceneLoader.ImportMesh('', '', 'G_girl03.glb', this._scene,
            (meshes, particle, askeleton, animtions, nodes) => {
                this.mesh = meshes[0]
                this.meshes = meshes;
                this.mesh.parent = this
                this.animBlender = new AnimBlender(animtions, this._scene, 'idle')
                this.ellipsoid = new Vector3(0.5, 1, 0.5)
                this.ellipsoidOffset = new Vector3(0, 0.85, 0)
                this.position=new Vector3((Math.random()*10),0.41,(Math.random()*10));

                if(window.game.sceneMeshParams && window.game.sceneMeshParams.navigationPlugin && window.game.sceneMeshParams.crowd)
                {
                    var agentParams = {
                        radius: 0.5,
                        height: 1,
                        maxAcceleration: 4.0,
                        maxSpeed: 2.5,
                        collisionQueryRange: 0.5,
                        pathOptimizationRange: 0.0,
                        separationWeight: 1.0};
                    this.crowd = window.game.sceneMeshParams.crowdAI
                    this.navigationPlugin = window.game.sceneMeshParams.navigationPlugin
                    let position = this.navigationPlugin.getClosestPoint(this.position)

                    this.agIndex = this.crowd.addAgent(position, agentParams, this);

                    this.beginRandomWalk()
                }
                this._scene.onBeforeRenderObservable.add(()=> {
                    if(this.crowd)
                    {
                                
                        let vel = this.crowd.getAgentVelocity(this.agIndex);
                        if (vel.length() < 0.2)
                        {
                            this.animBlender.setTargetAnim('idle');
                        }
                        else
                        {
                            vel.normalize();                               
                            let currentTranslate = new Vector3(vel.x, 0, vel.z).normalize();

                            let targetRotation = Quaternion.RotationQuaternionFromAxis(currentTranslate.clone(), Vector3.Up(), currentTranslate.cross(Vector3.Up())).multiply(Quaternion.RotationYawPitchRoll(Math.PI * 1.5, 0, 0));
                            
                            this.mesh.rotationQuaternion = Quaternion.Slerp(this.mesh.rotationQuaternion, targetRotation, 0.2);
                            this.animBlender.setTargetAnim('run')
                        }
                    }
    
                });
            })
    }
}
<template>
  <div id="app">
    <!-- <loading-program ref="loadingProgram" :tem_id="Number(tem_id)" v-show="loadingShow && tem_id == 44" @initNav="initNav" @changeLoadingStatus="changeLoadingStatus" class="loading-program-new"></loading-program> -->
    <bottom-nav ref="bottomNavs" v-show="tem_id == 44" :tem_id="Number(tem_id)"  @showHelpTab="showHelpTab" style="z-index:1200"></bottom-nav>
    <loading-program-new :tem_id="Number(tem_id)" ref="loadingProgram" :percentage="percentage" class="loading-program-new"  v-show="loadingShow" @changeLoadingStatus="changeLoadingStatus"> </loading-program-new>
    <!-- <person-select @showLoading="showLoading" v-if="personSelectShow" class="person-select" ref="personSelect"></person-select>
    <person-select-pc @showLoading="showLoading" v-if="personSelectShowPc" class="person-select-pc" ref="personSelectPc"></person-select-pc>
    <person-select-land-scape @showLoading="showLoading" v-if="personSelectShowLandscape" ref="personSelectLandScape"></person-select-land-scape> -->
    <nav-new ref="navNew" v-show="tem_id != 44" :tem_id="Number(tem_id)"></nav-new>

    <!-- 弹出商品详情页 -->
    <pop-com ref="popCom" @closePop="closePop" :product_image="productImage" :tem_id="Number(tem_id)" v-show="popCom && tem_id==86"></pop-com>
    <!-- 弹出提示语 -->
    <nh-pop ref="nhPop" :tem_id="Number(tem_id)" v-show="nhPop && tem_id==86" @click.native="toNhDetail()"></nh-pop>  
    <!-- 弹出视频 -->
    <video-page ref="videoPage" :video_url="videoUrl" @closeVideo="closeVideo" v-show="videoShow"></video-page>
    <el-dialog
        title="操作提示"
        width="70%"
        :visible.sync="showHelp">
      <slot></slot>
      <el-image :src="helpSrc"></el-image>
    </el-dialog>
    
    <el-image-viewer
        v-if="showViewer"
        :on-close="closeViewer"
        :url-list="srcList"
    ></el-image-viewer>

    <img :src="wapImage"  v-show="wapImageShow" ref="tes" preview="1">

    <!-- <div id="right-menu" v-show="rightMemu">
      <ul>
        <li @click="switchFullScreen" class="menu-item"> 
          <span class="iconfont">&#xe649;</span>
        </li>
      </ul>
    </div> -->
    <canvas onselectstart="return false;" refs="tt" id="canvas" style="width: 100%;height: 100%;"></canvas>

    <!-- <div class="loading-program" v-show="loadingShow">
      <div>
        <div>
          <img src="sf02.png"/>
        </div>
        <div>
          <el-progress :text-inside="true" :stroke-width="22" :percentage="percentage" color="black"></el-progress>
        </div>
      </div>
    </div> -->
    <!-- <div class="bottom-left-img-show" v-show="bottomLeftShow" @click="showThumbnail">
      <div>
        <img src="piclist.png">
      </div>
      <div>
        场景
      </div>
    </div> -->
    <div id="ll3d" v-if="showLL3d">
      <el-button @click="showLL3d=false" class="el-icon-close" style="position: absolute;right: 22%;top: 15%"
                 circle></el-button>
      <iframe width="100%" height="480"
              :src="ll3dSrc"
              style="width: 100%;height: 100%;background: transparent;" frameborder="0" allowfullscreen
              mozallowfullscreen="true" webkitallowfullscreen="true" onmousewheel=""></iframe>

    </div>

    <thumbnail v-show="isThumbnail" class="thumbnail"/>


    <!-- <div id="ll3d" v-if="isShowModel">
        <el-button @click="isShowModel = false" class="el-icon-close" style="position: absolute;right: 22%;top: 15%"
                 circle></el-button>
        <iframe width="100%" height="100%"
                :src="viewModelSrc"
                style="width: 100%;height: 100%;background: transparent;" frameborder="0" allowfullscreen  allowTransparency="true" 
                mozallowfullscreen="true" webkitallowfullscreen="true" onmousewheel="">
        </iframe>
    </div> -->
  </div>


</template>

<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import {Game} from "@/js/game";
import {getQueryVariable,getUserAgentInfo} from "@/js/tool";
import wx from 'weixin-js-sdk'
import thumbnail from './components/thumbnail.vue'
import loadingProgram from './components/loadingProgram.vue'
import loadingProgramNew from './components/loadingProgramNew.vue'
import personSelect from './components/personSelect.vue'
import personSelectPc from './components/personSelectPc.vue'
import personSelectLandScape from './components/personSelectLandScape.vue'
import navNew from './components/navNew.vue'
import popCom from './components/popCom.vue'
import videoPage from './components/video.vue'
import nhPop from './components/nhPop.vue'
import ViewModel from './ViewModel.vue';
import bottomNav from './components/bottomNav.vue';
import { getExhibitionTitle,onUlocate,addExhiVisit } from "@/js/api.js";
export default {
  name: 'Home',
  components: {
    ElImageViewer,
    thumbnail,
    ViewModel,
    "loading-program":loadingProgram,
    "bottom-nav":bottomNav,
    "loading-program-new":loadingProgramNew, 
    "person-select" : personSelect,
    "person-select-pc" : personSelectPc,
    "person-select-land-scape" : personSelectLandScape,
    "nav-new" : navNew,
    "pop-com" : popCom,
    "nh-pop" : nhPop,
    "video-page" : videoPage
  },
  data() {
    return {
      showLL3d: false,
      ll3dSrc: 'https://www.ll3d.com/model/8a99686f5ebdc1ad015ec29d447e01bf?nobackground=true',
      personSelectShow: false,
      percentage: 0,
      loadingShow: true,
      status: '',
      userData:[],
      musicEnable: true,
      showHelp:false,
      player:new Audio('autumn-talk.mp3'),
      playingAudio:null,
      rightMemu: false,
      isThumbnail:false,
      bottomLeftShow: false,
      imgUrl: '',
      srcList: [
        
      ],
      showViewer: false,
      wapImage: '',
      wapImageShow: false,
      isShowModel:false,
      tem_id:getQueryVariable("tem_id") || 0,
      mch_id:getQueryVariable("mch_id") || 0,
      viewModelSrc:'/viewModel',
      muted:false,
      helpSrc:'',
      token:getQueryVariable("token") || 0,
      visit_id: 0,
      tem_id_dy: getQueryVariable("tem_id_dy") || 0,
      platformtype: getQueryVariable("platformtype") || 3,
      personSelectShowPc:false,
      personSelectShowLandscape:false,
      popCom: false,
      nhPop: false,
      videoShow: false,
      productImage:'',
      productImageList: [
        "https://qn.ky3d.com/multi-exhibition/%E7%89%99%E8%86%8F.png",
        "https://qn.ky3d.com/multi-exhibition/%E9%9D%A2%E8%86%9C.png",
        "https://qn.ky3d.com/multi-exhibition/%E6%B4%97%E9%9D%A2%E5%A5%B6.png",
        "https://qn.ky3d.com/multi-exhibition/%E6%B2%90%E6%B5%B4%E9%9C%B2.png",
        "https://qn.ky3d.com/multi-exhibition/%E6%B4%97%E5%8F%91%E9%9C%B2.png"
      ],
      videoUrl: ''
    }
  },
  created(){
    //根据当前的mch_id与tem_id来查找当前的展馆名称
    let w = document.documentElement.clientWidth
    let h = document.documentElement.clientHeight

    if(w < 900 && h < 400){
        this.personSelectShowLandscape = true
    }else{
        if(w <= 768){
            this.personSelectShow = true
        }else{
            this.personSelectShowPc = true
        }
    }
    this.getExhibitionTitle();
    
    let versions = getUserAgentInfo();
    if(versions.mobile){
      this.helpSrc = 'sj-kg.png';
    }else{
      this.helpSrc = 'dn-kg.png';
    }
    let token = this.token
    localStorage.setItem('token',token)
    let tem_id_dy = this.tem_id_dy
    localStorage.setItem('tem_id_dy',tem_id_dy)
    this.addExhiVisit();
  },
  methods: {
    openIframe(url){
        this.showLL3d = true
        this.ll3dSrc = url
    },
    openVideo(url){
        this.videoUrl = url
        this.videoShow = true
    },
    closeVideo(){
        this.videoShow = false
    },
    closePop(){
        this.popCom = false
    },
    toNhDetail(){
        this.nhPop = false
        this.popCom = true
    },
    switchNhTip(type,productType = 0){
        //判断当前环境
        if(!this.isPhone()){
            return;
        }
        if(type == 1){
            this.nhPop = true
        }
        if(type == 2){
            this.nhPop = false
            return;
        }
        this.productImage = this.productImageList[productType]
    },
    showLoading(){
        this.personSelectShow = false
        this.personSelectShowPc = false
        this.personSelectShowLandscape = false
        this.loadingShow = true
    },
    toNhView(){
        wx.miniProgram.navigateTo({
            url:'../view-nh/view-nh?image=' + this.productImage,
            success:function(res){
                console.log(res)
            }
        });
    },
    //停止漫游
    toWxapp(){
        wx.miniProgram.redirectTo({
            url:'../getExhibition/getExhibition',
            success:function(res){
                console.log(res)
            }
        });
    },
    toWxappHomePage(){
        wx.miniProgram.switchTab({
            url:"../index/index", //若tabbar地址是 /page/home/index
            success:function(res){
                console.log(res)
            }
        })
    },
    toWechatWebView(url){
        let nowEnv = localStorage.getItem('envType');
        if(nowEnv == 1){
            let gender = getQueryVariable("gender");
            let glb = getQueryVariable("glb");
            let urls = url + '&glb=' + glb + '&gender' + gender
            let newUrl = encodeURIComponent(JSON.stringify(urls));
            wx.miniProgram.redirectTo({
                url:'../babylonWebview/babylonWebview?url=' + newUrl,
                success:function(res){
                    console.log(res)
                }
            });
        }else{
            window.location.assign(url);
        }
    },
    getExhibitionTitle(){
      getExhibitionTitle({
        "mch_id":this.mch_id,
        "tem_id":this.tem_id
      }).then( res => {
        if(res.data.error == 0){
          document.title = res.data.data.title
        }else{
          document.title = '元宇宙展馆'
        }
      })
    },
    popCommodity(){
        //该方法弹出商品弹窗



    },
    stopWander(){},
    initNav(){
      this.$refs.bottomNavs.setInitStatus('导航');
    },
    changeLoadingStatus(){
        this.loadingShow = false;
    },
    showHelpTab(){
      this.showHelp = true;
    },
    pauseTest(){
        let token = this.token
        localStorage.setItem('token',token)
        let visit_id = this.visit_id
        localStorage.setItem('visit_id',visit_id)
        if(token && visit_id){
            let url = 'wapapi/addons/yb_guanwang/core/index.php/api/wechatoa/editExhiVisit'
            let data = {
                'token':token,
                'visit_id': visit_id,
                platformtype: this.platformtype
            }
            fetch(url,{
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
                keepalive:true
            })
        }
    },
    addExhiVisit(){
 
        let tem_id_dy = localStorage.getItem('tem_id_dy');
        let token = decodeURI(localStorage.getItem('token'));
        let platformtype = this.platformtype
        addExhiVisit({
            token:token,
            tem_id_dy: tem_id_dy,
            platformtype:platformtype
        }).then(res => {
            if(res.data.error == 0){
                this.visit_id = res.data.visit_id
            }
        })
    },
    onUlocate(locate_title){
        let tem_id_dy = localStorage.getItem('tem_id_dy');
        let token = localStorage.getItem('token');
        if(tem_id_dy && token){
            let praiseClass = this.praiseClass
            if(praiseClass){
                this.type = 2
            }
            if(!praiseClass){
                this.type = 1
            }
            onUlocate({
                token:token,
                tem_id_dy: tem_id_dy,
                locate_title:locate_title
            }).then(res => {
                if(res.data.error == 0){
                    console.log(res)
                }
            })
        }
    },
    // 测试生成模型
    showModel(modelUrl){
      this.viewModelSrc ='/viewModel?tem_id='+getQueryVariable("tem_id")+'&murl='+modelUrl;
      console.log(this.viewModelSrc)
      this.isShowModel = true;

    //   this.$refs.modelView.viewModelOpen()
    },
    isPhone() {
      var info = navigator.userAgent;
      var isPhone = /mobile/i.test(info);
      return isPhone;
    },
    stopMove () {
      const m = (e) => { e.preventDefault() };
      document.body.style.overflow = 'hidden';
      document.addEventListener("touchmove", m, false); // 禁止页面滑动
    },
// 开启页面滚动
    move () {
      const m = (e) => { e.preventDefault() };
      document.body.style.overflow = 'auto';
      document.removeEventListener("touchmove", m, true);
    },

    closeViewer(){
      this.showViewer = false
      this.move();
    },
    zoomImg(img,locate_title = ''){
      //判断当前是手机浏览还是PC浏览
    //   this.onUlocate(locate_title);
      if(this.isPhone()){
        this.wapImage = img;
        setTimeout(() => {
          this.$refs.tes.click();
        },300)
      }else{
        setTimeout(() => {
          this.showViewer = true;
          this.stopMove();
          // this.srcList[0] = 'imag/' + img + '.jpg'
          this.srcList[0] = img;
        },300)
      }
    },
    playAudio(url){
      // return;
      if(this.playingAudio){
        this.playingAudio.pause();
        this.playingAudio = null;
      }
      if(url != ''){
      
        this.playingAudio = new Audio(url);
        try{
          this.playingAudio.play();
          if(this.muted){
             this.playingAudio.muted = this.muted
          }
        }catch(err){
          alert('error')
          }

      }
    },
    switchMusic(){
      this.muted = !this.muted;
      if(this.playingAudio){
        this.playingAudio.muted = this.muted;
      }
      window.game.sceneMeshParams.videos.map(vd=>{
          vd.muted = this.muted;
      });
      if(window.mp3)
      {
        console.log(this.muted)
        window.mp3.muted = this.muted;
      }
    },
    zoomModel(){
      
        this.isShowModel = true;
        // this.viewModelSrc = url;
    },
    showThumbnail(){
      this.isThumbnail = !this.isThumbnail
    },
    switchFullScreen() {
        if (document.fullscreenElement) {
            document.exitFullscreen()
        }
        document.documentElement.requestFullscreen()
    },
    toggleMusic() {
      var musicEnable = this.musicEnable
      if(musicEnable){
        this.musicEnable = false;
        this.player.pause();
      }else{
        this.musicEnable = true;
        this.player.play();
      }
      
    },
    getIsMuted(){
      return this.muted;
    }
  },  
  mounted() {
    if(!getQueryVariable("tem_id")){
      this.$message({
        showClose: true,
        message: '无法获取正确展馆编号',
        type: 'error'
      });
      return;
    }
    const glbSetting = this.exhibitionPostionXYZ
    window.toWxapp = this.toWxapp
    window.toNhView = this.toNhView
    window.toWxappHomePage = this.toWxappHomePage
    window.toWechatWebView = this.toWechatWebView
    window.switchMusic = this.switchMusic
    window.switchFullScreen = this.switchFullScreen
    window.showModel = this.showModel
    window.zoomImg = this.zoomImg
    window.playAudio = this.playAudio
    window.getIsMuted = this.getIsMuted;
    window.switchNhTip = this.switchNhTip
    window.openVideo = this.openVideo
    window.openIframe = this.openIframe
    const canvas = document.getElementById("canvas");
    window.game = new Game(canvas,glbSetting)

    window.game.loadingManage.onDisplayObs.add(e => {
      console.log('loadStart')
    })
    window.game.loadingManage.onHideObs.add(e => {
      console.log('loadEnd')
      this.percentage = 100;
      this.status = 'success';
      setTimeout(() => {
        this.$refs.loadingProgram.readyEnter();
      }, 300)
    })
    window.game.loadingManage.onLoadingProgress.add(progress => {
      this.percentage = Number(progress)
    })
    window.addEventListener('beforeunload',e => this.pauseTest(e))
  },
  destroyed(){
    window.removeEventListener('beforeunload',e => this.pauseTest(e))
  },
  watch:{
    percentage(n){
      if(n > 95){
        this.rightMemu = true
        if(this.tem_id == 37 || this.tem_id == 44 || this.tem_id == 43){
        this.bottomLeftShow = true
        }

      }
    }
  }

}
</script>

<style>
* {
  padding: 0;
  margin: 0;
}
#ll3d {
  position: fixed;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}
.loading-program-new {
  z-index: 1999;
}
@media screen and (max-width: 768px) {
  .thumbnail{
    width: 100%;
    height: 70px;
    position: fixed;
    left: 0;
    bottom: 10%;
    background: rgba(0,0,0,0.3);
    overflow: scroll;
  }
}
@media screen and (min-width: 768px) {
  .thumbnail{
    width: 100%;
    height: 100px;
    position: fixed;
    left: 0;
    bottom: 10%;
    background: rgba(0,0,0,0.3);
  }
}
.bottom-left-img-show{
  position: fixed;
  bottom: 10px;
  left: 10px;
  width: 50px;
  height: 50px;
  cursor: pointer;

}
.bottom-left-img-show > div:first-child{
  width: 50px;
  height: 30px;
  display: flex;
  justify-content: center;
}
.bottom-left-img-show > div:first-child > img{
  width: 30px;
  height: 30px;
  object-fit: cover;
}
.bottom-left-img-show > div:nth-child(2){
  width: 50px;
  height: 20px;
  font-size: 12px;
  text-align: center;
  color: rgb(112,112,112);

}
html, body, #app {
  width: 100%;
  height: 100%;
}
canvas {
  z-index: 1000;
}
.music.roll {
  display: block;
  animation: music 2s linear infinite;
}
.loading-program {
  z-index: 1200;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: white url('/sf01.png') no-repeat center center; ;
  background-size:cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading-program > div{
  width: 80%;
  max-width: 400px;

}
.loading-program > div > div:first-child{
  width: 100%;
}
.loading-program > div > div:first-child > img{
  width: 100%;
}
.loading-program > div > div:nth-child(2){
  width: 100%;
}



@keyframes music {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(90deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(270deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@font-face {
  font-family: 'iconfont';  /* Project id 3013934 */
  src: url('//at.alicdn.com/t/font_3013934_4rvk5e127bb.woff2?t=1652164516109') format('woff2'),
       url('//at.alicdn.com/t/font_3013934_4rvk5e127bb.woff?t=1652164516109') format('woff'),
       url('//at.alicdn.com/t/font_3013934_4rvk5e127bb.ttf?t=1652164516109') format('truetype');
}
.iconfont {
  font-family: "iconfont", serif !important;
  font-size: 18px;
  padding: 8px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #F8F8FF;
}


.menu-item {
  padding: 5px;
  font-size: 20px;
  color: white;
  cursor: pointer;
  margin-bottom: 20px;
}
.menu-item.praise {
  color: #1989FA;
}
.menu-item.commentShow{
   color: #1989FA; 
}
li {
  list-style-type: none;
}
#right-menu {

  position: fixed;
  display: -webkit-flex; /* Safari */
  width: 30px;
  height: 200px;
  z-index: 1000;
  top: 32px;
  right: 24px;
}

.top-icon{
  position: fixed;
  top: 0px;
  right:  0px; 
}
.shadow-top{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
  z-index: 9999;
}
</style>

import {ActionManager, ExecuteCodeAction, Observable, Scene, MeshBuilder, Mesh} from "@babylonjs/core";
import * as GUI from "@babylonjs/gui";

const sportList = [
    {
        "name": "pic",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c68018906366e59002b"
    },
    {
        "name": "pic.001",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890639f001002d"
    },
    {
        "name": "pic.002",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890b092c000068"
    },
    {
        "name": "pic.003",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c68018906421a58002f"
    },
    {
        "name": "pic.004",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890a2c5a250031"
    },
    {
        "name": "pic.005",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890a32ba860034"
    },
    {
        "name": "pic.006",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890a35388d0035"
    },
    {
        "name": "pic.007",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890a3725660036"
    },
    {
        "name": "pic.008",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890a3db8580038"
    },
    {
        "name": "pic.009",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c68018a3f1a0627006a"
    },
    {
        "name": "pic.010",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890b0b27270069"
    },
    {
        "name": "pic.011",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890a4c2435003b"
    },
    {
        "name": "pic.012",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890a4f93dc003d"
    },
    {
        "name": "pic.013",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890a52fba5003e"
    },
    {
        "name": "pic.014",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890a54c0f9003f"
    },
    {
        "name": "pic.015",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890a566cb80040"
    },
    {
        "name": "pic.016",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890a57ce480041"
    },
    {
        "name": "pic.017",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890a59187c0042"
    },
    {
        "name": "pic.018",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890a5aa4a60043"
    },
    {
        "name": "pic.019",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890a5c5c260044"
    },
    {
        "name": "pic.020",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890a5e9e1b0045"
    },
    {
        "name": "pic.021",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890a60342b0046"
    },
    {
        "name": "pic.022",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890a622fdc0047"
    },
    {
        "name": "pic.023",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890a64ac600048"
    },
    {
        "name": "pic.024",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890ade879e0064"
    },
    {
        "name": "pic.025",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890ad38c59005c"
    },
    {
        "name": "pic.026",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890a66b5270049"
    },
    {
        "name": "pic.027",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890aba90d1004a"
    },
    {
        "name": "pic.028",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890abc0c98004b"
    },
    {
        "name": "pic.029",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890abd5344004c"
    },
    {
        "name": "pic.030",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890ac0083a004e"
    },
    {
        "name": "pic.031",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890ac153e6004f"
    },
    {
        "name": "pic.032",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890ac2ac2a0050"
    },
    {
        "name": "pic.033",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890ac3b9680051"
    },
    {
        "name": "pic.034",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890abecac7004d"
    },
    {
        "name": "pic.035",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890abecac7004d"
    },
    {
        "name": "pic.036",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890ac527d50052"
    },
    {
        "name": "pic.037",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890ac69d510053"
    },
    {
        "name": "pic.038",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890ac7efb60054"
    },
    {
        "name": "pic.039",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890ac939350055"
    },
    {
        "name": "pic.040",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890acae1920056"
    },
    {
        "name": "pic.041",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890acbf9040057"
    },
    {
        "name": "pic.042",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890adb22ac0061"
    },
    {
        "name": "pic.043",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890adb22ac0061"
    },
    {
        "name": "pic.044",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890acd280a0058"
    },
    {
        "name": "pic.045",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890acf5ce80059"
    },
    {
        "name": "pic.046",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890ad0f98c005a"
    },
    {
        "name": "pic.047",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890ad2578a005b"
    },
    {
        "name": "pic.048",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890ad559ec005d"
    },
    {
        "name": "pic.049",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890ad8846e005f"
    },
    {
        "name": "pic.050",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890add76cc0063"
    },
    {
        "name": "pic.051",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890add76cc0063"
    },
    {
        "name": "pic.052",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890adff31b0065"
    },
    {
        "name": "pic.053",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890adff31b0065"
    },
    {
        "name": "pic.054",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890ad71ff3005e"
    },
    {
        "name": "pic.055",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890ad71ff3005e"
    },
    {
        "name": "pic.056",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890ad9c1e50060"
    },
    {
        "name": "pic.057",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890adc61000062"
    },
    {
        "name": "pic.058",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c680188f0953e18000e"
    },
    {
        "name": "pic.059",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c68018905d5e11c0011"
    },
    {
        "name": "pic.060",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c68018905df87650014"
    },
    {
        "name": "pic.061",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c68018905dcfb040013"
    },
    {
        "name": "pic.062",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c68018905eeb4aa0015"
    },
    {
        "name": "pic.063",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c68018905f470060016"
    },
    {
        "name": "pic.064",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c68018905fb63520017"
    },
    {
        "name": "pic.065",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c68018905ff40430018"
    },
    {
        "name": "pic.066",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890602172a0019"
    },
    {
        "name": "pic.067",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890604a467001a"
    },
    {
        "name": "pic.068",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c680189060a4578001b"
    },
    {
        "name": "pic.069",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c680189060cd0bf001c"
    },
    {
        "name": "pic.070",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c680189060e57ed001d"
    },
    {
        "name": "pic.071",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890610371b001e"
    },
    {
        "name": "pic.072",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c680189061d1e24001f"
    },
    {
        "name": "pic.073",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c680189061fc7990020"
    },
    {
        "name": "pic.074",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c6801890622bdf00021"
    },
    {
        "name": "pic.075",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c680189062546730022"
    },
    {
        "name": "pic.076",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c68018906276e490023"
    },
    {
        "name": "pic.077",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c68018906295fde0024"
    },
    {
        "name": "pic.078",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c680189062ac4840025"
    },
    {
        "name": "pic.079",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c680189062c01dc0026"
    },
    {
        "name": "pic.080",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c680189062f89180027"
    },
    {
        "name": "pic.081",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c680189063134930028"
    },
    {
        "name": "pic.082",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c680189063298a20029"
    },
    {
        "name": "pic.083",
        "url": "https://xhwcx.ll3d.com/model/a47ac47288f04c68018906341a00002a"
    }
]

const triggerList = [
    {
        "name": "C1",
        "sportList":[
            "pic",
            "pic.001",
            "pic.002",
            "pic.003",
            "pic.004",
            "pic.005"
        ]
    },
    {
        "name": "C2",
        "sportList":[
            "pic.006",
            "pic.007",
            "pic.008",
            "pic.009",
        ]
    },
    {
        "name": "C3",
        "sportList":[
            "pic.010",
            "pic.011",
            "pic.012",
            "pic.013",
        ]
    },
    {
        "name": "C4",
        "sportList":[
            "pic.014",
            "pic.015",
            "pic.016",
            "pic.017",
            "pic.018",
        ]
    },
    {
        "name": "C5",
        "sportList":[
            "pic.019",
        ]
    },
    {
        "name": "C6",
        "sportList":[
            "pic.020",
            "pic.021",
            "pic.022",
            "pic.023",
        ]
    },
    {
        "name": "C7",
        "sportList":[
            "pic.024",
            "pic.025",
            "pic.026",
        ]
    },
    {
        "name": "C8",
        "sportList":[
            "pic.027",
            "pic.028",
            "pic.029",
            "pic.030",
        ]
    },
    {
        "name": "C9",
        "sportList":[
            "pic.031",
            "pic.032",
            "pic.033",
            "pic.034",
            "pic.035",
            "pic.036",
            "pic.037",
            "pic.038",
        ]
    },
    {
        "name": "C10",
        "sportList":[
            "pic.039",
            "pic.040",
            "pic.041",
            "pic.042",
            "pic.043",
            "pic.044",
            "pic.045",
            "pic.046",
        ]
    },
    {
        "name": "C11",
        "sportList":[
            "pic.047",
            "pic.048",
            "pic.049",
            "pic.050",
            "pic.051",
            "pic.052",
            "pic.053",
            "pic.054",
        ]
    },
    {
        "name": "C12",
        "sportList":[
            "pic.055",
            "pic.056",
            "pic.057",
        ]
    },
    {
        "name": "C13",
        "sportList":[
            "pic.058",
            "pic.059",
            "pic.060",
            "pic.061",
            "pic.062",
            "pic.063",
        ]
    },
    {
        "name": "C14",
        "sportList":[
            "pic.064",
            "pic.065",
            "pic.066",
            "pic.067",
            "pic.068",
            "pic.069",
            "pic.070",
            "pic.071",
        ]
    },
    {
        "name": "C15",
        "sportList":[
            "pic.072",
            "pic.073",
            "pic.074",
            "pic.075",
            "pic.076",
            "pic.077",
            "pic.078",
            "pic.079",
        ]
    },
    {
        "name": "C16",
        "sportList":[
            "pic.080",
            "pic.081",
            "pic.082",
            "pic.083"
        ]
    }
]


export class HotSportManage {
    onSportClickObservable: Observable<{ name: string, url: string }>

    constructor(scene: Scene,player) {
        this.scene = scene
        this.onSportClickObservable = new Observable()
        this.advancedTexture = GUI.AdvancedDynamicTexture.CreateFullscreenUI('ui')
        this.hotSportList = []
        this.cameraMesh = MeshBuilder.CreateBox('cameraBox',{size:0.1},this.scene);//创建检测区域碰撞使用的Box
        this.cameraMesh.isPickable = false;
        this.cameraMesh.visibility = 0;
        this.cameraMesh.parent = player;

        // const btn = GUI.Button.CreateImageOnlyButton('gifbtn','test.gif')
        
        // btn.width = "200px"
        // btn.height = "200px"
        // this.advancedTexture.addControl(btn)
    }

    async update() {

        this.sportList = sportList
        this.sportList.forEach(item => {
            const hotSport =  GUI.Button.CreateImageOnlyButton('gifbtn','btn_img.png')
            hotSport.width = "80px"
            hotSport.height = "80px"
            // hotSport.alpha = 0.5
            hotSport.thickness = 0
            // hotSport.cornerRadius = 360
            const mesh = this.scene.getNodeByName(item.name)
            // console.log(mesh)
            if (mesh) {
                hotSport.onPointerClickObservable.add(e => {
                    this.onSportClickObservable.notifyObservers(item)
                })
                this.advancedTexture.addControl(hotSport)
                hotSport.linkWithMesh(mesh)
            }
            hotSport.isVisible = false
            this.hotSportList.push({'name':item.name,'hotSport':hotSport})


        })
        this.triggerList = triggerList
        this.triggerList.forEach(trigger=>{
            const mesh = this.scene.getMeshByName(trigger.name)
            mesh.isVisible = false
            mesh.isPickable = false

            if(!mesh.actionManager){
                mesh.actionManager = new ActionManager(this.scene)
            }
            mesh.actionManager.registerAction(new ExecuteCodeAction(
                {
                trigger:ActionManager.OnIntersectionEnterTrigger,
                parameter:this.cameraMesh
            },(evt)=>{
                trigger.sportList.forEach(sportName=>{
                    console.log(sportName,' in')
                    this.hotSportList.filter(item=>{
                        return item.name == sportName
                    })[0].hotSport.isVisible = true
                })
            }
            ))
            mesh.actionManager.registerAction(new ExecuteCodeAction(
                {
                trigger:ActionManager.OnIntersectionExitTrigger,
                parameter:this.cameraMesh
            },(evt)=>{
                trigger.sportList.forEach(sportName=>{
                    console.log(sportName,' out')
                    this.hotSportList.filter(item=>{
                        return item.name == sportName
                    })[0].hotSport.isVisible = false
                })
            }
            ))
        })

    }
}

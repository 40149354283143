import {
    ArcRotateCamera,
    CubeTexture,
    Engine,
    FreeCamera,
    Matrix,
    Quaternion,
    Scene,
    SceneLoader,
    Vector3,
    DracoCompression

} from "@babylonjs/core";

import {Player} from "@/js/playerControl/player";

import { PlayerTPS } from "@/js/playerControl/playerTPS";

import { LoadingManage } from "@/js/loadingManage";

import { SceneParams } from "@/js/sceneParams";

import {GalleryManage} from "@/js/gallery";

import {CameraControl} from "@/js/playerControl/cameraControl";

import '@babylonjs/inspector';

import "@babylonjs/loaders";

import { CustomJoyInput } from "@/js/customJoyInput";

import {getQueryVariable} from "@/js/tool";

import { ThinSprite } from "@babylonjs/core/Sprites/thinSprite";

import axios from "axios"

import { SceneMeshParams } from "@/js/sceneMeshParams";

import { navigationManager } from "@/js/navigationManager"
import { HotSportManage } from "@/js/xiehe_hotSportManage";
import { ShicaiHotSportManage } from "@/js/shicai_hotSportManage";
//读取配置config.json文件
async function getGalleryConfig() {
    let response = await axios.get("./template/" + getQueryVariable("tem_id") + "/config.json")
    return response.data
}
export class Game {
    constructor(canvas: HTMLCanvasElement,glbSetting){
        //初始化场景
        this.engine = new Engine(canvas, true,null,false)
        this.canvas = canvas
        this.scene = new Scene(this.engine)
        this.isSetAgent = false;

        //获取当前场景的tem_id(模板id)
        const tem_id = getQueryVariable("tem_id");
        this.tem_id = tem_id
        const mch_id = getQueryVariable("mch_id");
        this.mch_id = mch_id;
        this.glbSetting = glbSetting
        let text = getQueryVariable("test_text");

        let glb_url = getQueryVariable("glb");
        glb_url = decodeURIComponent(glb_url);
        console.log(glb_url);

        let gender = getQueryVariable("gender");
        console.log(gender)
        
        console.log(decodeURI(text))

        //处理场景参数  包含特殊处理  针对scene的处理都放到这个类里
        this.sceneParams = new SceneParams(this.scene,tem_id)
        this.sceneParams.initParams();

        //处理页面加载
        this.loadingManage = new LoadingManage()
        this.engine.loadingScreen = this.loadingManage

        //提高镜头亮度
        // this.pipeLine = new DefaultRenderingPipeline('defaultPipe', true, this.scene, this.scene.cameras)
        // this.pipeLine.samples = 8
        // this.pipeLine.imageProcessing.toneMappingType = 1
        // this.pipeLine.imageProcessing.vignetteEnabled = true
        // this.pipeLine.chromaticAberrationEnabled = false
        // this.pipeLine.chromaticAberration.aberrationAmount = 30
        // this.pipeLine.chromaticAberration.radialIntensity = 0.65
        // this.pipeLine.bloomEnabled = true

        DracoCompression.Configuration.decoder.wasmUrl =
        "babylonConfigs/draco_wasm_wrapper_gltf.js";
        DracoCompression.Configuration.decoder.wasmBinaryUrl =
        "babylonConfigs/draco_decoder_gltf.wasm";
        DracoCompression.Configuration.decoder.fallbackUrl =
        "babylonConfigs/draco_decoder_gltf.js";
        
        //生成默认相机
        if(tem_id !=44 && tem_id!= 43 && tem_id!= 103){
            const defaultCamera = new FreeCamera("defaultCamera", new Vector3(0, 5, -10), this.scene);
            defaultCamera.attachControl(true);
        }else{
            this.cameraControl = new CameraControl(tem_id,this.scene,glbSetting,this.canvas,"FirstPerson")
        }

        if(tem_id == 103){
            this.hotSportManage = new HotSportManage(this.scene,this.cameraControl.cameraMesh);
            // hotSportManage.update();
            console.log('create hotsport')
            
            this.hotSportManage.onSportClickObservable.add(eventData => {
                console.log(eventData.url)
                openIframe(eventData.url + '?nobackground=true')
              })
            
        }
        if(tem_id == 44){
            this.shicaiHotSportManage = new ShicaiHotSportManage(this.scene,this.cameraControl.cameraMesh);
            console.log('create shicaiHotsport')
        }
        //debug模式开启
        // this.scene.debugLayer.show({ handleResize: true, embedMode: true })
        window.addEventListener('keydown', e => {
            if (e.key === "i") {
                if (this.scene.debugLayer.isVisible()) {
                    this.scene.debugLayer.hide()
                } else {
                    this.scene.debugLayer.show({ handleResize: true, embedMode: true })
                }
            }
            if(e.key === 'e')
            {
                this.playerDance(5);
            }
        })
        this.isReady = false;
        console.log(glb_url)
        if(glb_url == undefined || glb_url == null || glb_url == false || glb_url == 'false' || glb_url==''){
            this.createCharacter("https://qn.ky3d.com/person-select/Mesh3.glb",0);
        }else{
            this.createCharacter(glb_url,gender);
        }
        //导入场景
        this.CreateScene(glbSetting,tem_id).then( res => {

            // 抗锯齿
            this.engine.setHardwareScalingLevel(0.4);
            this.isReady = true;

            
            
            //场景模型初始化
            this.sceneMeshParams = new SceneMeshParams(this.scene,tem_id,res.envTex,res.meshes)

            const main_url = 'https://scg.ky3d.com/?tem_id='

            if(window.game.tem_id == 66){//企业馆
                this.sceneMeshParams.setMeshTeleport(this.scene.getMeshByName('Interaction'),main_url + '76&mch_id='+ mch_id);
            }
    
            if(window.game.tem_id == 74){//毕业作品
                this.sceneMeshParams.setMeshTeleport(this.scene.getMeshByName('Interaction'),main_url + '76&mch_id='+ mch_id);
                this.sceneMeshParams.setMeshTeleport(this.scene.getMeshByName('Interaction.001'),main_url + '76&mch_id='+ mch_id);
                this.sceneMeshParams.setMeshTeleport(this.scene.getMeshByName('Interaction.002'),main_url + '76&mch_id='+ mch_id);
            }
    
    

    
            if(window.game.tem_id == 76){
                this.sceneMeshParams.setMeshTeleport(this.scene.getMeshByName('SHZInteraction'),main_url + '77&mch_id='+ mch_id);
                this.sceneMeshParams.setMeshTeleport(this.scene.getMeshByName('SCGInteraction'),main_url + '66&mch_id='+ mch_id);
                this.sceneMeshParams.setMeshTeleport(this.scene.getMeshByName('BYZPZInteraction'),main_url + '74&mch_id='+ mch_id);
                this.sceneMeshParams.setMeshTeleport(this.scene.getMeshByName('DJGInteraction'),main_url + '80&mch_id='+ mch_id);
                this.sceneMeshParams.setMeshTeleport(this.scene.getMeshByName('KJGInteraction'),main_url + '79&mch_id='+ mch_id);
            }
            if(window.game.tem_id == 77){//书法馆
                this.sceneMeshParams.setMeshTeleport(this.scene.getMeshByName('Interaction'),main_url + '76&mch_id='+ mch_id);
            }
    
            if(window.game.tem_id == 79){//科技馆
                this.sceneMeshParams.setMeshTeleport(this.scene.getMeshByName('Interaction'),main_url + '76&mch_id='+ mch_id);
            }

            if(window.game.tem_id == 80){//科技馆
                this.sceneMeshParams.setMeshTeleport(this.scene.getMeshByName('Interaction'),main_url + '76&mch_id='+ mch_id);
            }
            
            //创建导航网格
            // if(tem_id >= 48 && tem_id <= 69 || tem_id >= 74){
            //     this.navigationManager = new navigationManager('collision',this.scene,false);
            //     if(this.cameraControl){
            //         if(!this.cameraControl.isSetAgent){
            //             // this.navigationManager.scene =this.scene;
            //             this.navigationManager.addMainPlayerNavigation(this.cameraControl.playerCamera,this.scene)
            //             this.cameraControl.isSetAgent = true;
            //             this.isSetAgent = true;
            //         }
            //         }else{
            //             this.isSetAgent = false;
            //     }
            // }


            //调用接口替换全局内需要替换的图片
            this.galleryManage = new GalleryManage(this.scene)

            // this.scene.createOrUpdateSelectionOctree();
            

            //读取完毕隐藏loading界面
            this.engine.hideLoadingUI();

        })
        
        this.engine.runRenderLoop(() => {
            this.scene.render()
        })

        window.addEventListener("resize", e => {
            this.engine.resize()
        })
    }

    playerDance(inNum){
        this.cameraControl.playerCamera.dance(inNum);
    }
    switchPlayerSpeed(){
        this.cameraControl.playerCamera.switchSpeed();
    }

    replaceMaterial(matUrl){
        if(this.shicaiHotSportManage){
            this.shicaiHotSportManage.replaceMaterial(matUrl)
        }
    }




    startRoaming(){
        playAudio('音频/前言.mp3');
        setTimeout(() => {
            wanderStart(4)
        }, 12000);
        // this.cameraControl.playCameraAnimLoop(0);
        // this.cameraControl.startRoaming();
    }

    createCharacter(characterName,gender)
    {
        console.log("createCharacter :"+characterName)
        this.cameraControl = new CameraControl(this.tem_id,this.scene,this.glbSetting,this.canvas,characterName,gender)
    }
    
    async CreateScene(glbSetting,tem_id):Promise<void> {
        let glbUrl = "./template/" + tem_id + "/";
        let qnUrl = "https://qn.ky3d.com/" + tem_id + "/";
        let glbName= tem_id + ".glb";
        let envName = tem_id + ".env";
        var url = qnUrl;
        // if (process.env.NODE_ENV !== 'production') { 
        //     console.log('test')
        //    var url = glbUrl;
        // }

        const envTex = CubeTexture.CreateFromPrefilteredData(url + envName,this.scene);//加载不同场馆的环境贴图

        const { meshes } = await SceneLoader.ImportMeshAsync('',url,glbName, this.scene, evt => {
            let loadedPercent = 0;
            if (evt.lengthComputable) {
                loadedPercent = (evt.loaded * 100 / evt.total).toFixed(0);
            }
            else {
                let dlCount = evt.loaded / (1024 * 1024);
                loadedPercent = Math.floor(dlCount * 100.0) / 100.0;
            }
            console.log(loadedPercent)
            this.loadingManage.sendProgress(loadedPercent)
        })
        const arcRotateCamera = new ArcRotateCamera("arcRotateCamera",0,1,30, new Vector3(0, 5, -10), this.scene);
        arcRotateCamera.attachControl(true);
        arcRotateCamera.setTarget(meshes[0]);
        arcRotateCamera.upperRadiusLimit = 60;
        arcRotateCamera.lowerRadiusLimit = 5;
        arcRotateCamera.upperBetaLimit = Math.PI/2;
        this.arcRotateCamera = arcRotateCamera;

        this.meshRootNode = meshes[0];


        // setTimeout( e => {
        //     this.pipeLine.imageProcessing.contrast = 1
        //     this.pipeLine.imageProcessing.exposure = 1
        // },1000)

        
        //调整场景模型匹配人物模型大小
        if(glbSetting[getQueryVariable("tem_id")].glbScale != null){
            this.scene.rootNodes.forEach((rns) => {
                if(rns.id == '__root__'){
                    rns.scaling.x = glbSetting[getQueryVariable("tem_id")].glbScale;
                    rns.scaling.y = glbSetting[getQueryVariable("tem_id")].glbScale;
                    rns.scaling.z = -glbSetting[getQueryVariable("tem_id")].glbScale;
                }
            })
        }

        

        return Promise.resolve({
            'envTex': envTex,
            'meshes': meshes
        });
    }


    switchMusic() {
        if (this.music.isPlaying) {
            this.music.pause()
            return false
        } else {
            this.music.play()
            this.music.setVolume(1)
            return true
        }
    }
}

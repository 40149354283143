import { PlayerTPS } from "@/js/playerControl/playerTPS";

import { Player } from "@/js/playerControl/player";

import { Scene,Vector3,Quaternion, ActionManager, ExecuteCodeAction, MeshBuilder, Animation, PickingInfo, PointerInfo, PointerEventTypes, DefaultRenderingPipeline, ArcRotateCamera, Viewport, RenderTargetTexture, StandardMaterial, Color3, Color4 } from "@babylonjs/core";

import { cameraTransforms } from "@/js/playerControl/cameraAnimTransforms";

import { EmojiCreator } from "@/js/playerControl/emojiCreator";
import { HotSportManage } from "@/js/xiehe_hotSportManage";
export class CameraControl {
    constructor(tem_id:Number,scene:Scene,glbSetting,canvas,characterName,gender) {
        this.tem_id = tem_id
        this.glbSetting = glbSetting
        this.scene = scene
        this.canvas = canvas
        this.characterName = characterName
        this.gender = gender
        this.playingAnim = null;
        this.playingLoopAnim = null;
        this.isLoop = true;
        // this.scene.onPointerObservable.add((pointerInfo)=>{//监听屏幕点击事件
        //     switch(pointerInfo.type){
        //         case PointerEventTypes.POINTERUP:
        //             if(this.isRoaming){
        //                 wanderStart(4)
        //             }
        //             // if(this.playingAnim)//如果摄像机动画正在播放就停止播放
        //             // {
        //             //     this.playingAnim.stop();
        //             // }
        //             // console.log('click')
        //             // if(this.playingLoopAnim){
        //             //     this.stopRoaming()
        //             //     // console.log(this.currentArea)
        //             // }
                    
        //     }

        // })
        this.time = 4;
        this.speed = 2;
        this.currentIndex = 0;
        this.currentArea = 0;
        this.isRoaming = true;

        this.timeOuts = [];
        this.areaStop =[1,5,7,9,12] 

        this.animTime = [35,47,47,56,56]

        this.cameraPerson = -1;
        this.bSetArc = false;



        this.temTransforms = cameraTransforms;

        this.isSetAgent = false;


        window.addEventListener('keydown', e => {
            if (e.key === "v") {
                this.switchCameraPerson();
            }
            if(e.key === "t"){
                this.swicthArc();
            }
        })
        this.initCamera()
    }
    continueRoaming(){
    //     console.log(this.playingLoopAnim,this.currentArea)
    //     if(this.playingLoopAnim)
    //     {
    //         this.isRoaming = true;
    //         this.jumpToPoint(++this.currentIndex);

    //     }else{

        
    //     switch(this.currentArea){
    //         case 0:
    //             this.jumpToPoint(0);
    //             break;
    //         case 1:
    //             this.jumpToPoint(2);
    //             break;
    //         case 2:
    //             this.jumpToPoint(6);
    //             break;
    //         case 3:
    //             this.jumpToPoint(8);
    //             break;
    //         case 4:
    //             this.jumpToPoint(10);
    //             break;
    //         case 5:
    //             this.jumpToPoint(13);
    //             break;
    //     }
    // }
    }


    setRoaming(isRoaming){
        this.isRoaming = isRoaming;
    }

    async startRoaming(){
        let animUrl = "./template/" + this.tem_id + "/area5.json";
        let startAnims = await Animation.ParseFromFileAsync('areaAnim',animUrl)
        this.playingAnim = this.scene.beginDirectAnimation(this.camera,startAnims,0,43*30,false,undefined,()=>{
            this.playingAnim = null;
        });
        // console.log(startAnims)
        // this.camera.animations = startAnims;
        // this.scene.beginAnimation(this.camera,0,35*30,true);

    }

    async playAnimByIndex(index){
        if(index ==0){
            index =1;
        }
        this.stopRoaming();
        this.isRoaming = true;
        this.playIntroMusic(index)
        this.currentIndex = index;
        let animUrl = "./template/" + this.tem_id + "/s"+index+".json";
        let startAnims = await Animation.ParseFromFileAsync('areaAnim',animUrl)
        this.playingAnim = this.scene.beginDirectAnimation(this.camera,startAnims,0,this.animTime[index-1]*30,false,undefined,()=>{
            this.playingAnim = null;
            this.camera.rotation.z = 0;
            wanderStart(4)
        });
    }
    playIntroMusic(index){
        switch(index){
            case 1:
                playAudio('音频/序厅.mp3');
                break;
            case 2:
                playAudio('音频/一键换装.mp3');
                break;
            case 3:
                playAudio('音频/原材料展示.mp3');
                break;
            case 4:
                playAudio('音频/荣誉墙.mp3');
                break;
            case 5:
                playAudio('音频/体验区.mp3');
                break;
        }

    }

    stopRoaming(){
        if(this.playingLoopAnim)
        {
            this.playingLoopAnim.stop();
            this.playingLoopAnim = null;
        }
        if(this.playingAnim)
        {
            this.playingAnim.stop();
            this.playingAnim = null;
        }
        // console.log(this.timeOuts)
        this.timeOuts.map((item)=>{
            clearTimeout(item);
        })
        this.timeOuts = []
        this.isLoop = false;
        this.isRoaming = false;
        // playAudio('');
    }

    jumpRoaming(){

        this.jumpToPoint(++this.currentIndex);
        // this.playCameraAnimLoop(this.currentIndex);
    }

    jumpToPoint(pointIndex){//第一人称传送
        this.stopRoaming();//停止当前漫游
        const targetTransforms= this.temTransforms[this.tem_id];
        if(targetTransforms){
            //组装向量
            pointIndex %=targetTransforms.length;
            const targetPosition = new Vector3(targetTransforms[pointIndex].targetPositionx,targetTransforms[pointIndex].targetPositiony,targetTransforms[pointIndex].targetPositionz);
            const targetRotation = new Vector3(targetTransforms[pointIndex].targetRotationx,targetTransforms[pointIndex].targetRotationy,targetTransforms[pointIndex].targetRotationz);            
            const [pAnim,rAnim] = this.cameraAnim(targetPosition,targetRotation,1/30);
            this.playIntroAudio(pointIndex);
            this.scene.beginDirectAnimation(this.camera,[pAnim,rAnim],0,1,false,undefined,()=>{
                if(this.isRoaming){
                    this.isLoop = true;
                    this.playCameraAnimLoop(pointIndex);
                }else{
                    this.stopRoaming()
                }
            });
            
            // this.camera.position =targetPosition;
            // this.camera.rotation =targetRotation;

        }

    }
    //初始化相机函数 为每个相机增添不同参数
    async initCamera(){

        let tem_id = Number(this.tem_id);
        let defaultPipeline;
        switch (tem_id) {
            case 37:
                await this.createThirdPersonCamera();
                this.playerCamera.camera.alpha = Math.PI/2
                break;
            case 43:
            case 103:
                this.createFirstPersonCamera();
                this.camera.rotation = new Vector3(0, Math.PI/2,0);
                break;
            case 44:
                this.createFirstPersonCamera();
                // this.playerCamera.mesh.rotationQuaternion = Quaternion.FromEulerVector(new Vector3(0, -Math.PI/2, 0))
                // this.playerCamera.camera.alpha = Math.PI
                // eslint-disable-next-line no-case-declarations
                // let defaultPipeline4 = new DefaultRenderingPipeline("default", true, this.scene, this.scene.cameras);
                // defaultPipeline4.bloomEnabled = true;
                // defaultPipeline4.bloomWeight = 0.03;
                this.camera.rotation = new Vector3(0, Math.PI,0);
                break;
            case 45:
                await this.createThirdPersonCamera();
                this.playerCamera.camera.alpha = Math.PI
                break;
            case 54:
                await this.createThirdPersonCamera();
                this.playerCamera.camera.alpha = -Math.PI*(3/4)
                break;    
            // case 58:
            //     await this.createThirdPersonCamera();
            //     // eslint-disable-next-line no-case-declarations
            //     let pipeline = new DefaultRenderingPipeline("default", true, this.scene, this.scene.cameras);
            //     pipeline.bloomEnabled = true;
            //     pipeline.bloomWeight = 0.1;
            //     break;
            case 68:
                await this.createThirdPersonCamera();
                // eslint-disable-next-line no-case-declarations
                defaultPipeline = new DefaultRenderingPipeline("default", true, this.scene, this.scene.cameras);
                defaultPipeline.bloomEnabled = true;
                defaultPipeline.bloomWeight = 0.1;
                break;
            case 70:
                await this.createThirdPersonCamera();
                this.playerCamera.camera.alpha = Math.PI
                // eslint-disable-next-line no-case-declarations
                defaultPipeline = new DefaultRenderingPipeline("default", true, this.scene, this.scene.cameras);
                defaultPipeline.bloomEnabled = true;
                defaultPipeline.bloomWeight = 0.1;
                break;
            case 74:
                await this.createThirdPersonCamera();
                this.playerCamera.camera.alpha = Math.PI
                defaultPipeline = new DefaultRenderingPipeline("pipeLine", true, this.scene, this.scene.cameras);
                defaultPipeline.imageProcessing.toneMappingEnabled = true
                defaultPipeline.imageProcessing.vignetteEnabled = true
                defaultPipeline.samples = 4
                defaultPipeline.sharpenEnabled = false;
                defaultPipeline.bloomEnabled = true;
                break
            case 76:
                await this.createThirdPersonCamera();
                defaultPipeline = new DefaultRenderingPipeline("default", true, this.scene, this.scene.cameras);
                defaultPipeline.samples = 8
                defaultPipeline.imageProcessing.toneMappingType = 1
                defaultPipeline.imageProcessing.vignetteEnabled = true
                defaultPipeline.chromaticAberrationEnabled = false;
                defaultPipeline.chromaticAberration.aberrationAmount = 30
                defaultPipeline.chromaticAberration.radialIntensity = 0.65
                defaultPipeline.bloomEnabled = true
                defaultPipeline.bloomWeight = 0.2;
                this.playerCamera.camera.alpha = Math.PI*(1/2)
                setTimeout(() => {
                    
                    defaultPipeline.imageProcessing.contrast = 1.5
                    defaultPipeline.imageProcessing.exposure = 1
                }, 500);
                break;
            case 80:{
                await this.createThirdPersonCamera();
                this.playerCamera.camera.alpha = Math.PI*(1/2)

                break;
            }
            case 87:
            case 94:
            case 100:
            {
                await this.createThirdPersonCamera();
                this.playerCamera.camera.alpha = Math.PI*(1/2)
                break;
            }
            default:
                await this.createThirdPersonCamera();
                break;
        }

    }
    createFirstPersonCamera(){

        let glbSetting = this.glbSetting
        this.camera = new Player('player', new Vector3(glbSetting[this.tem_id].positionx, glbSetting[this.tem_id].positiony, glbSetting[this.tem_id].positionz), this.scene)
        this.camera.maxZ = 500
        this.camera.speed = 0.3
        this.cameraPerson = 1;
        
        this.camera.attachControl(this.canvas)
        if(this.cameraMesh == null)
        {
            this.cameraMesh = MeshBuilder.CreateBox('cameraBox',{size:0.5},this.scene);//创建检测区域碰撞使用的Box
            this.cameraMesh.isPickable = false;
            this.cameraMesh.visibility = 0;
        }
        this.cameraMesh.parent = this.camera;
        // window.game.sceneMeshParams.setHotSportsAction();
    }
    async createThirdPersonCamera(){
        let glbSetting = this.glbSetting
        this.playerCamera =await new PlayerTPS('player',this.characterName,this.gender, this.scene)
        this.playerCamera.position = new Vector3(glbSetting[this.tem_id].positionx, glbSetting[this.tem_id].positiony, glbSetting[this.tem_id].positionz)
        console.log(this.playerCamera)
        this.cameraPerson = 3;
        if(this.cameraMesh == null)
        {
            this.cameraMesh = MeshBuilder.CreateBox('cameraBox',{size:0.1},this.scene);//创建检测区域碰撞使用的Box
            this.cameraMesh.isPickable = false;
            this.cameraMesh.visibility = 0;
            this.cameraMesh.position.y = 2;
        }
        this.cameraMesh.parent = this.playerCamera;

        this.emoji = new EmojiCreator(this.cameraMesh,this.scene);

        console.log('create',window.game.isReady)

        // if(window.game.tem_id >= 48 && window.game.tem_id <= 69 || window.game.tem_id >= 74){
        //     if(window.game.isReady){
        //         if(!window.game.isSetAgent){
        //             if(window.game.navigationManager){
        //                 window.game.navigationManager.addMainPlayerNavigation(this.playerCamera,this.scene);
        //                 // console.log('addMainPlayerNavigation')
        //                 window.game.isSetAgent = true;
        //                 this.isSetAgent = true;
        //             }
        //         }
        //     }
            
        // }

        if(window.game.tem_id == 75){
            this.playerCamera.camera.radius = 2.5
            this.playerCamera.camera.upperRadiusLimit = 2.5
        }




        // const box = MeshBuilder.CreateBox("playerBox",{size:3},this.scene);
        // box.layerMask = 2;

        // this.miniMapCamera = new ArcRotateCamera("miniMapCamera",-Math.PI/2,0.001,60,this.playerCamera,this.scene);
        // this.scene.activeCamera = this.playerCamera.camera;
        // this.playerCamera.camera.layerMask = 1;
        // this.miniMapCamera.layerMask = 2;

        // const renderTargetMiniMap = new RenderTargetTexture('miniMapTexture',1024,this.scene,true,true);
        // this.scene.customRenderTargets.push(renderTargetMiniMap);
        // renderTargetMiniMap.activeCamera = this.miniMapCamera;
        // renderTargetMiniMap.renderList = this.scene.meshes;

        // const miniMapPlane = MeshBuilder.CreatePlane('miniMapPlane',{size:4},this.scene);
        // miniMapPlane.scaling = new Vector3(0.007,0.007,0.007);
        // miniMapPlane.position = new Vector3(-0.053,0.026,0.1);
        // miniMapPlane.isPickable = false;

        // const miniMapMt = new StandardMaterial("miniMapMt",this.scene);
        // miniMapMt.diffuseColor = new Color3(1,1,1);
        // miniMapMt.diffuseTexture = renderTargetMiniMap;
        // miniMapMt.specularColor = Color3.Black();
        // miniMapMt.diffuseTexture.uScale = 1;
        // miniMapMt.diffuseTexture.vScale = 1;
        // miniMapMt.diffuseTexture.level = 1.2; // intensity

        // miniMapMt.emissiveColor = new Color3(1,1,1); // backlight
        // miniMapPlane.material = miniMapMt;
        // miniMapPlane.parent = this.playerCamera.camera;
        // miniMapPlane.edgesWidth = 5.0;
        // miniMapPlane.edgesColor = new Color4(1, 1, 1, 1);



        // this.scene.registerBeforeRender(()=>{
        //     box.position.z = this.playerCamera.position.z;
        //     box.position.x = this.playerCamera.position.x;
        //     box.position.y = 10;
        //     box.rotation.y = this.playerCamera.camera.rotation.y;
        // })




    }

    setFirstCameraRotation(rot){
        this.camera.rotation = rot;
    }

    setFirstCameraPosition(target){
        this.camera.TeleportTo(target);
    }
    
    setThirdCameraAlphaBeta(alpha,beta){
        this.playerCamera.camera.alpha = alpha;
        this.playerCamera.camera.beta = beta;
    }

    setThirdCameraMove(vec){
        this.playerCamera.move(vec);
    }


    swicthArc(){
        if(this.bSetArc)
        {
            this.bSetArc = false;
            this.scene.activeCamera = this.playerCamera.camera;
            this.playerCamera.switchVisibility(true);
            this.playerCamera.meshes.map(mesh=>{
                mesh.visibility = 1;
            })
        }else{
            this.bSetArc = true;
            this.scene.activeCamera = window.game.arcRotateCamera;
            this.playerCamera.switchVisibility(false);
            this.playerCamera.meshes.map(mesh=>{
                mesh.visibility = 0;
            })
        }
    }

    switchCameraPerson(){

        if(this.cameraPerson == 1)
        {
            this.playerCamera.switchFirstPerson(false);
            // if(this.playerCamera == null)
            // {
            //     this.playerCamera =new  PlayerTPS('player', this.scene)
            // }else{
            //     this.playerCamera.meshes.map(mesh=>{
            //         mesh.visibility = 1;
            //     })
            // }
            // // console.log(this.playerCamera);
            // this.cameraMesh.parent = this.playerCamera;
            // this.playerCamera.switchVisibility(true)

            // this.scene.activeCamera = this.playerCamera.camera;
            this.cameraPerson = 3;

            // this.playerCamera.position = new Vector3(this.camera.position.x,this.camera.position.y-1.35,this.camera.position.z)
        }else if(this.cameraPerson == 3)
        {
            this.playerCamera.switchFirstPerson(true);
            // if(this.camera ==null)
            // {
            //     this.camera = new Player('player',new Vector3(this.playerCamera.position.x,1.86,this.playerCamera.position.z),this.scene);
            //     this.camera.maxZ = 500
            //     this.camera.speed = 0.3
        
            //     this.camera.attachControl(this.canvas)
            // }
            // this.cameraMesh.parent = this.camera;
            // this.playerCamera.switchVisibility(false)
            // this.camera.position = new Vector3(this.playerCamera.position.x,this.playerCamera.position.y+1.35,this.playerCamera.position.z)
            // this.scene.activeCamera = this.camera;
            this.cameraPerson = 1;
            // this.playerCamera.meshes.map(mesh=>{
            //     mesh.visibility = 0;
            // })

        }

    }

    interactionListen(){
        this.scene.meshes.filter(mesh => mesh.name.indexOf('ModelInteraction') !== -1).forEach(mesh=>{
            mesh.visibility = 0;
            mesh.isPickable = false;
            if(!mesh.actionManager){
                mesh.actionManager = new ActionManager(this.scene);
            }
            mesh.actionManager.registerAction(new ExecuteCodeAction(
                {
                    trigger: ActionManager.OnIntersectionEnterTrigger,
                    parameter: this.cameraMesh
                },
                (evt)=>{
                    console.log('enter')
                    window.game.galleryManage.setHotSportsVisible(true,mesh);
                }
            ))
            mesh.actionManager.registerAction(new ExecuteCodeAction(
                {
                    trigger: ActionManager.OnIntersectionExitTrigger,
                    parameter: this.cameraMesh
                },
                (evt)=>{
                    window.game.galleryManage.setHotSportsVisible(false,mesh);
                    console.log('exit')
                }
            ))
        })
    }


    stayTimeListen(){
        let stayTime = []
        let stayTimeJsonString;
        this.scene.meshes.filter(mesh => mesh.name.indexOf('area') !== -1).forEach(mesh => {
            if(!mesh.actionManager){
                mesh.actionManager = new ActionManager(this.scene);
            }
            stayTime.push({
                area:mesh.name,
                sTime:0
            });
            mesh.isPickable = false;
            mesh.visibility = 0;
            let enterTime;
            mesh.actionManager.registerAction(new ExecuteCodeAction(
                {
                    trigger: ActionManager.OnIntersectionEnterTrigger,
                    parameter: this.cameraMesh
                },
                (evt)=>{
                    this.areaInteraction(true,mesh.name);//开始播放
                    enterTime = Date.now();
                    // console.log('enter',mesh.name);
                }
            ))
            mesh.actionManager.registerAction(new ExecuteCodeAction(
                {
                    trigger: ActionManager.OnIntersectionExitTrigger,
                    parameter: this.cameraMesh
                },
                (evt)=>{
                    this.areaInteraction(false,mesh.name);//停止场景的播放
                    let seconds = parseInt((Date.now()-enterTime)/1000);
                    console.log('you stopped in area',mesh.name,'for',seconds,'seconds.')
                    stayTime.forEach((item,i)=>{
                        if(item.area == mesh.name){
                            item.sTime += seconds;
                        }
                    })
                    // console.log('exit',mesh.name);
                    stayTimeJsonString = JSON.stringify(stayTime);
                    
                    localStorage.setItem('stayTime',stayTimeJsonString);
                    console.log(localStorage.getItem('stayTime'));
                }
            ))
        })
        stayTimeJsonString = JSON.stringify(stayTime);
        localStorage.setItem('stayTime',stayTimeJsonString);
    }

    areaInteraction(isPlay,meshName){
        //播放视频、音乐、交互等
        let vd;
        if(isPlay){
            if(meshName.indexOf("HZ") > -1){
                // if(!this.isRoaming){
                //     setMaterial('进入')
                // }
                window.game.sceneMeshParams.setHotSportVisibility(true);
                console.log('in')
            }
            if(meshName.indexOf("XT") > -1){
                vd=window.game.sceneMeshParams.videos[1];
                vd.play();
            }
            if(meshName.indexOf("YS") > -1){
                vd=window.game.sceneMeshParams.videos[0];
                vd.play();
            }
            if(meshName.indexOf("RY") > -1){
                vd=window.game.sceneMeshParams.videos[2];
                vd.play();
            }
            // if(vd){
            //     vd.volume = 0.05;
            //     vd.muted =getIsMuted();
            // }
        }else{
            if(meshName.indexOf("HZ") > -1){
                // setMaterial('退出')
                window.game.sceneMeshParams.setHotSportVisibility(false)
            }
            if(meshName.indexOf("XT") > -1){
                window.game.sceneMeshParams.videos[1].pause();
            }
            if(meshName.indexOf("YS") > -1){
                window.game.sceneMeshParams.videos[0].pause();
            }
            if(meshName.indexOf("RY") > -1){
                window.game.sceneMeshParams.videos[2].pause();
            }  
        }

    }


    //相机动画(传入位置、旋转值、运动时间(1s = 30fps))
    cameraAnim(position,rotation,timeFrame){
        const startFrame = 0;
        const frameRate = 30;
        const endFrame = timeFrame*frameRate;
        const pKeyFrames = [];
        pKeyFrames.push({
          frame: startFrame,
          value: this.camera.position
        })
        pKeyFrames.push({
          frame: endFrame,
          value: position
        })
    
        const rKeyFrames = [];
        rKeyFrames.push({
          frame: startFrame,
          value: this.camera.rotation
        })
        rKeyFrames.push({
          frame: endFrame,
          value: rotation
        })
        const pAnim = new Animation('pAnim','position',frameRate,Animation.ANIMATIONTYPE_VECTOR3,Animation.ANIMATIONLOOPMODE_CYCLE);
        const rAnim = new Animation('rAnim','rotation',frameRate,Animation.ANIMATIONTYPE_VECTOR3,Animation.ANIMATIONLOOPMODE_CYCLE);
    
    
        pAnim.setKeys(pKeyFrames);
        rAnim.setKeys(rKeyFrames);

        return [pAnim,rAnim];
    }
    playIntroAudio(index){
        // console.log('playMusic%d',index);
        switch (index){
            case 0:{
                this.currentArea = 1;
                break;
            }
            case 2:{
                playAudio('音频/序厅.mp3');
                this.currentArea = 1;
                break;
            }
            case 5 :{
                this.currentArea = 2;
                break;
            }
            case 6:{
                playAudio('音频/一键换装.mp3');
                this.currentArea = 2;
                break;
            }
            case 7 :{
                this.currentArea = 3;
                break;
            }
            case 8:{
                playAudio('音频/原材料展示.mp3');
                this.currentArea = 3;
                break;
            }
            case 9 :{
                this.currentArea = 4;
                break;
            }
            case 10:{
                playAudio('音频/荣誉墙.mp3');
                this.currentArea = 4;
                break;
            }
            case 12 :{
                this.currentArea = 5;
                break;
            }
            case 13:{
                playAudio('音频/体验区.mp3');
                this.currentArea = 5;
                break;
            }
            case 16:{
                playAudio('音频/结语.mp3');
                this.currentArea = 1;
                break;
            }
        }
    }
    fixRotationY(){
        if(this.camera.rotation.y>=0){
            return;
        }
        this.camera.rotation.y +=2*Math.PI;
        this.fixRotationY();
    }
    playCameraAnim(position,rotation){
        const length = this.camera.position.subtract(position).length()
        // v=s/t 
        this.fixRotationY();
        this.camera.rotation.y %=2*Math.PI;
        if(Math.abs(this.camera.rotation.y-2*Math.PI) < this.camera.rotation.y){
            this.camera.rotation.y -=2*Math.PI
        }
        let time =this.time;
        const speed = this.speed;
        
        if(length>speed)
        {
            time = length/speed
        }
        
        let anim = this.cameraAnim(position,rotation,time);
        console.log('play',anim)
        this.stopRoaming()
        this.playingAnim = this.scene.beginDirectAnimation(this.camera,anim,0,time*30,false,undefined,()=>{
            this.playingAnim = null;
        });
    }

    playCameraAnimLoop(index){
            if(this.isLoop){
                const targetTransforms= this.temTransforms[this.tem_id];
                if(targetTransforms){
                    // return;
                    console.log('loop:%d',index);
                    
                    // this.playIntroAudio(index);
                    if(index >= targetTransforms.length){//播放完停止
                        return;
                    }
                    this.currentIndex = index;
                    let time =this.time;
                    const speed = this.speed;
                    // index %=targetTransforms.length;//播放完循环
                    //组装向量
                    const targetPosition = new Vector3(targetTransforms[index].targetPositionx,targetTransforms[index].targetPositiony,targetTransforms[index].targetPositionz);
                    const targetRotation = new Vector3(targetTransforms[index].targetRotationx,targetTransforms[index].targetRotationy,targetTransforms[index].targetRotationz);
                    
                    
                    const length = this.camera.position.subtract(targetPosition).length()//计算路径长度
                    // t=s/v   v应该是恒定的 时间随路程长度增加
                    if(length>speed && !targetPosition.equals(Vector3.Zero())){
                        time = length/speed;
                    }
                    let anim = []; 
                    let [pAnim,rAnim]= this.cameraAnim(targetPosition,targetRotation,time);
                    if(targetPosition.equals(Vector3.Zero())){//判断如果没有位移则只进行旋转
                        anim.push(rAnim);
                    }else{
                        anim = [pAnim,rAnim]
                    }
                    
                    if(this.areaStop.includes(index)){
                        return;
                    }
                    this.playingLoopAnim = this.scene.beginDirectAnimation(this.camera,anim,0,time*30,false,undefined,()=>{
                        this.timeOuts.push(setTimeout(()=>{
                            this.playCameraAnimLoop(++index);
                        },targetTransforms[index].stayTime*1000));
                    });
                }
            }
        
    }

    //场景传送
    changeScence(type) {
        
        if(this.tem_id == 37){
            let firstMesh = this.playerCamera.mesh
            switch (type){
                case 1:{
                    this.playerCamera.position =  new Vector3(4.89, 0.16, 5.86);
                    firstMesh.rotationQuaternion = Quaternion.FromEulerVector(new Vector3(0, -Math.PI, 0))
                    this.playerCamera.camera.alpha = Math.PI/2
                    break;
                }
                case 2:{
                    this.playerCamera.position =  new Vector3(10.33, 0.16, 6.09);
                    firstMesh.rotationQuaternion = Quaternion.FromEulerVector(new Vector3(0, Math.PI/2, 0))
                    this.playerCamera.camera.alpha = Math.PI
                    break;
                }
                case 3:{
                    this.playerCamera.position =  new Vector3(10.33, 0.16, -5.5);
                    firstMesh.rotationQuaternion = Quaternion.FromEulerVector(new Vector3(0, Math.PI/2, 0))
                    this.playerCamera.camera.alpha = Math.PI
                    break;
                }
                case 4:{
                    this.playerCamera.position =  new Vector3(1.43, 0.16, -12.39);
                    firstMesh.rotationQuaternion = Quaternion.FromEulerVector(new Vector3(0, -Math.PI, 0))
                    this.playerCamera.camera.alpha = Math.PI/2
                    break;
                }
                case 5:{
                    this.playerCamera.position =  new Vector3(-10.65, 0.16, 5.57);
                    firstMesh.rotationQuaternion = Quaternion.FromEulerVector(new Vector3(0, -Math.PI/2, 0))
                    this.playerCamera.camera.alpha = 0
                    break;
                }
            }
        }
        if(this.tem_id == 44){
            if(this.playerCamera){
            let firstMesh = this.playerCamera.mesh
            switch(type){
                case 1:{
                    this.playerCamera.position =  new Vector3(13.77, 0.16, -.17);
                    firstMesh.rotationQuaternion = Quaternion.FromEulerVector(new Vector3(0, -Math.PI/2, 0))
                    this.playerCamera.camera.alpha = Math.PI;
                    break;
                }
                case 2:{
                    this.playerCamera.position =  new Vector3(11.67, 0.16, 9.83);
                    firstMesh.rotationQuaternion = Quaternion.FromEulerVector(new Vector3(0, -Math.PI/2, 0))
                    this.playerCamera.camera.alpha = Math.PI;
                    break;
                }
                case 3:{
                    this.playerCamera.position =  new Vector3(-10.52, 0.16, 6.99);
                    firstMesh.rotationQuaternion = Quaternion.FromEulerVector(new Vector3(0, Math.PI, 0))
                    this.playerCamera.camera.alpha = -Math.PI/2;
                    break;
                }
                case 4:{
                    this.playerCamera.position =  new Vector3(-7.57, 0.16, -1.25);
                    firstMesh.rotationQuaternion = Quaternion.FromEulerVector(new Vector3(0, Math.PI/4, 0))
                    this.playerCamera.camera.alpha = Math.PI/4;
                    break;
                }
                case 5:{
                    this.playerCamera.position =  new Vector3(3.77, 0.16, 6.04);
                    firstMesh.rotationQuaternion = Quaternion.FromEulerVector(new Vector3(0, Math.PI*(20/180), 0))
                    this.playerCamera.camera.alpha = Math.PI*(65/180);
                    break;
                }
                case 6:{
                    this.playerCamera.position =  new Vector3(-19.22, 0.16, 5.13);
                    firstMesh.rotationQuaternion = Quaternion.FromEulerVector(new Vector3(0, Math.PI*(45/180), 0))
                    this.playerCamera.camera.alpha = Math.PI*((55+180)/180);
                    break;
                }
            }
        }else{
            this.camera.rotation.y %= (2*Math.PI);//修复旋转值
            switch(type){
                case 1:{
                    const targetPosition = new Vector3(-37.64,1.26,2.70);
                    const targetRotation = new Vector3((-12/180)*Math.PI,Math.PI/2,0);
                    this.playCameraAnim(targetPosition,targetRotation);

                    

                    break;
                }
                case 2:{
                    const targetPosition = new Vector3(-9.76,1.26,2.26);
                    const targetRotation = new Vector3((-12/180)*Math.PI,(-9/180)*Math.PI,0);
                    this.playCameraAnim(targetPosition,targetRotation);
                    break;
                }
                case 3:{

                    break;
                }
                case 4:{

                    break;
                }
                case 5:{

                    break;
                }
                case 6:{

                    break;
                }
            }
        }
        }
        if(this.tem_id == 43){
            switch(type){
                case 1:{
                    this.camera.position = new Vector3(0,1.27,0.26);
                    this.camera.rotation = new Vector3(0,Math.PI/2,0);
                    break;
                }
                case 2:{
                    this.camera.position = new Vector3(12.55,1.27,-7.45);
                    this.camera.rotation = new Vector3(0,Math.PI,0);
                    break;
                }
                case 3:{
                    this.camera.position = new Vector3(9.75,1.27,-4.56);
                    this.camera.rotation = new Vector3(-(12/180)*Math.PI,(36/180)*Math.PI,0);
                    break;
                }
                case 4:{
                    this.camera.position = new Vector3(-7.66,1.27,-9.22);
                    this.camera.rotation = new Vector3(0,-Math.PI/2,0);
                    break;
                }
                case 5:{
                    this.camera.position = new Vector3(-11,1.27,-7.84);
                    this.camera.rotation = new Vector3(0,Math.PI,0);
                    break;
                }
                case 6:{
                    this.camera.position = new Vector3(-7.78,1.27,-7.65);
                    this.camera.rotation = new Vector3(0,(30/180)*Math.PI,0);
                    break;
                }
                case 7:{
                    this.camera.position = new Vector3(-8.53,1.27,8.83);
                    this.camera.rotation = new Vector3(0,-Math.PI/2,0);
                    break;
                }
            }
        }
        
    }
}



import * as GUI from "@babylonjs/gui";

export class EmojiCreator{
     constructor(mesh,scene){
        this.emojiPanel = GUI.AdvancedDynamicTexture.CreateFullscreenUI('emoji')
        // this.imgOut = new GUI.Ellipse('imgOut')
        // this.imgOut.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT;
        // this.imgOut.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_BOTTOM;

        this.emojiImgs = []

        for(let i = 1;i <= 8; ++i){
            const img = new GUI.Image('img'+i,"/emojiImg/emoji" + i + ".png");
            img.height = '150px'//175
            img.width = '150px'//175
            img.alpha = 0;
            this.emojiPanel.addControl(img);
            img.linkWithMesh(mesh);

            this.emojiImgs.push(img);
        }

        // this.img1 =new GUI.Image('img1',"/emojiImg/emoji1.png");
        // this.img1.height = '250px'//175
        // this.img1.width = '250px'//175
        // this.img1.alpha = 0;
        // // this.imgOut.addControl(this.img1);
        // this.emojiPanel.addControl(this.img1);
        // this.img1.linkWithMesh(mesh);
        // let alpha = 0;
        // scene.registerBeforeRender(()=>{
        //     this.emojiPanel.vOffset = Math.sin(alpha)/20;
        //     alpha += 0.01;
        // })

        this.isPlayingAnim = false;

        window.addEventListener('keydown', e => {
            if(e.key == "1"){
                this.showEmoji(2);
            }
        })
     }

     showEmoji(imgID){
        if(!this.isPlayingAnim && imgID >0 && imgID <= 8){
            this.isPlayingAnim = true;
            // switch(imgID){
            //     case 1:
            //         console.log("Anim img 1")
            //         animObj = this.img1;
            //         break;
            // }
            console.log("emoji play " + imgID);
            const animObj = this.emojiImgs[imgID-1];

            let alpha = 0;
            animObj.alpha = 0;
            animObj.scaleX = 0;
            animObj.scaleY = 0;
            // const id = setInterval(()=>{
            //     if(animObj.alpha < 1){
    
            //         animObj.alpha += 1/10;
            //     }
            //     if(animObj.scaleX < 0.8){
    
            //         animObj.scaleX += 1/10;
            //         animObj.scaleY += 1/10;
            //     }
            //     this.emojiPanel.vOffset = -Math.sin(alpha)/10;
            //     alpha += 0.01;
            //     console.log(alpha)
            // },500/60);
            // setTimeout(()=>{
            //     clearInterval(id);
            // },500);
            this.showAnim(animObj,alpha,1000/60);
    

        }
     }
     

     showAnim(animObj,alpha,deltaTime){
        setTimeout(()=>{
            if(animObj.alpha < 1){
                animObj.alpha += 1/10;
                animObj.scaleX += 1/10;
                animObj.scaleY += 1/10;
            }
            if(alpha < 0.6){
                this.emojiPanel.vOffset = -Math.sin(alpha)/10;
                alpha += 0.01;
                this.showAnim(animObj,alpha);
            }else{
                setTimeout(()=>{
                    animObj.alpha = 0;
                    this.isPlayingAnim = false;
                },3000);
            }
        },deltaTime)
     }
};
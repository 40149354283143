import {ActionManager, PointerEventTypes, Scene, setAndStartTimer, UniversalCamera, Vector3} from "@babylonjs/core";
import {CustomJoyInput} from "@/js/customJoyInput";


export class Player extends UniversalCamera {

    moveTimer: null

    constructor(name: string, position: Vector3, scene: Scene) {
        super(name, position, scene)
        //键位映射
        this.inputs.clear()
        this.inputs.addGamepad()
        this.inputs.addKeyboard()
        this.inputs.addMouse(true)
        // this.inputs.add(new CustomJoyInput())


        this.keysUp.push(87)
        this.keysDown.push(83)
        this.keysLeft.push(65)
        this.keysRight.push(68)
        //碰撞和重力
        this.checkCollisions = true
        this.applyGravity = true


        this.fovMode = Player.FOVMODE_VERTICAL_FIXED
        this.fov = Math.PI * 0.4
        this.minZ = 0.1

        this.speed = 1
        this.inertia = 0.8
        this.angularSensibility = 500
        this.ellipsoid = new Vector3(0.3, 0.75, 0.3);
        // if(scene.actionManager == null){
        //     scene.actionManager = new ActionManager(scene)
        // }

        this.canMove = true;
        this.pointDown = false;
        this.lastRotate = new Vector3(999,999,999)

        this._updatePosition()
        scene.onPointerObservable.add(e => {
            if (e.type === PointerEventTypes.POINTERPICK) {
                let isMove = true;
                scene.meshes.filter(mesh => mesh.name.indexOf('replace') > -1 || mesh.name.indexOf("对象017") > -1 || mesh.name.indexOf("video") > -1).map(mesh=>{
                    if(mesh == e.pickInfo.pickedMesh){
                        isMove = false;
                        return;
                    }
                })
                if(isMove){
                    if (Vector3.Dot(e.pickInfo.getNormal(), Vector3.Up()) > -0.1) {
                        let point = e.pickInfo.pickedPoint
                        let normal = e.pickInfo.getNormal(true)


                        let target = point.add(normal.scale(1.5))
                        let transFormJson = {target:target}
                        let transFormJsonString = JSON.stringify(transFormJson);

                        //发送摄像机移动参数
                        // console.log(transFormJsonString)


                    this.TeleportTo(point.add(normal.scale(1.5)))
                }}

            }
            if (e.type === PointerEventTypes.POINTERDOWN){
                this.pointDown = true;
                
            } 
            if (e.type === PointerEventTypes.POINTERUP){
                this.pointDown = false;
            }
            this.getCameraRotate();
        })


    }

    getCameraRotate(){
        
        if(this.pointDown){
            //发送摄像机旋转参数
            // console.log(this.rotation)
        }
    }

    
    TeleportTo(target: Vector3) {
        // console.log(target)
        if(this.canMove){
            this.canMove = false;
            let startPos = this.position.clone();
            let endPos = target.clone().add(Vector3.Up().scale(2));
            let len = Vector3.Distance(startPos, endPos)
            let lastRate = 0;

            let time = 2;

            if(len>2.5){
                time = len/2.5
            }
            this.moveTimer = setAndStartTimer({
                timeout: time*1000,
                contextObservable: this._scene.onBeforeRenderObservable,
                onTick: (e) => {
                    let delta = e.completeRate - lastRate
                    let translate = endPos.subtract(this.position).normalizeToNew().multiply(new Vector3(1, 0, 1).scale(delta * len))
                    this.cameraDirection = translate
                    this._updatePosition()
                    lastRate = e.completeRate
                },
                onEnded:()=>{
                    this.canMove = true;
                }
            })

    }
}

}


